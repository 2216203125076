import config from "../config/config";
import { getCurrentUser } from "./authService";

const apiEndpoint = config.apiEndpoint + "/api/task";

const taskService = ({ get, post }) => {
  async function createTask(newTask) {
    return post(apiEndpoint, newTask);
  }

  async function completeTask(completeData) {
    return post(apiEndpoint + "/complete", completeData);
  }

  async function completeUndoTask(taskId) {
    return post(apiEndpoint + "/complete/undo", taskId);
  }

  async function deleteTask(taskId) {
    return post(apiEndpoint + "/delete", taskId);
  }

  async function listSelfTasks() {
    return get(apiEndpoint + "/list/self");
  }

  async function listUserTasks(userId) {
    const url = userId ? "/list/user?userId=" + userId : "/list/user";
    return get(apiEndpoint + url);
  }

  async function updateAssignee(taskId, assignedTo) {
    return post(apiEndpoint + "/update/assignee", { taskId, assignedTo });
  }

  async function updateDueDate(taskId, dueDate) {
    return post(apiEndpoint + "/update/due", { taskId, dueDate });
  }

  async function updateDescription(taskId, description) {
    return post(apiEndpoint + "/update/description", {
      taskId,
      description,
    });
  }

  async function updateTitle(taskId, title) {
    return post(apiEndpoint + "/update/title", { taskId, title });
  }

  async function updateStatus(taskId, status) {
    return post(apiEndpoint + "/update/status", { taskId, status });
  }

  async function updateOrder(taskOrder) {
    return post(apiEndpoint + "/order", taskOrder);
  }

  async function updateSection(taskId, sectionId) {
    return post(apiEndpoint + "/update/section", { taskId, sectionId });
  }

  async function getTeamGroupedTasks(startDate, endDate) {
    return get(
      apiEndpoint +
        "/sum-by-members?teamId=" +
        getCurrentUser().teams[0].teamId +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate
    );
  }

  async function approveTest(taskId, workLog) {
    return post(apiEndpoint + "/approve-test", { taskId, workLog });
  }

  async function rejectTest(taskId, workLog, explanation) {
    return post(apiEndpoint + "/reject-test", { taskId, workLog, explanation });
  }

  async function sendtoTest(taskId, workLog, testAssignee) {
    return post(apiEndpoint + "/send-to-test", {
      taskId,
      workLog,
      testAssignee,
    });
  }

  return {
    createTask,
    completeTask,
    completeUndoTask,
    deleteTask,
    listSelfTasks,
    updateAssignee,
    updateDueDate,
    updateDescription,
    updateTitle,
    updateStatus,
    updateOrder,
    updateSection,
    getTeamGroupedTasks,
    approveTest,
    rejectTest,
    sendtoTest,
    listUserTasks,
  };
};

export default taskService;
