// @flow
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Collapse,
  OverlayTrigger,
  Tooltip,
  Badge,
  Button,
} from "react-bootstrap";
import classNames from "classnames";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { shorten } from "../../../../helpers/string";
import { TaskProps } from "../../../../core/task-status";
import useHttp from "../../../../hooks/useHttp";
import { useAlert } from "../../../../hooks/useAlert";

const Task = (props) => {
  const { task, selectTask } = props;

  const markCompleted = (e) => {};

  const getStatusColor = (status) => {
    const taskStatus = TaskProps[status];
    return taskStatus ? taskStatus.color : "secondary";
  };

  return (
    <Row
      className={`justify-content-sm-between mt-2${
        task.status === "completed" ? " opacity-50" : ""
      }`}
      onClick={() => selectTask(task)}
      style={{ cursor: "pointer" }}
    >
      <Col sm={5} className="mb-2 mb-sm-0">
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id={`task-${task.id}`}
            checked={task.status === "completed"}
            onChange={markCompleted}
          />
          <label
            className="form-check-label"
            htmlFor={`task-${task.id}`}
            onClick={() => selectTask(task)}
          >
            {task.title}
          </label>
          {task.status === "testing" && (
            <Badge className={classNames("me-1", "badge bg-info")}>test</Badge>
          )}
        </div>
      </Col>
      <Col sm={7}>
        <div className="d-flex justify-content-between">
          {task.user && (
            <div>
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip>
                    Assigned to {task.user.name} {task.user.surname}
                  </Tooltip>
                }
              >
                <div
                  className="rounded-circle bg-primary-lighten text-primary"
                  style={{
                    height: 30,
                    width: 30,
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <span>{task?.user?.code}</span>
                </div>
              </OverlayTrigger>
            </div>
          )}
          {task.Project && (
            <div>
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>Project Name: {task?.Project?.name}</Tooltip>}
              >
                <div>
                  <span>{shorten(task?.Project?.name, 20)}</span>
                </div>
              </OverlayTrigger>
            </div>
          )}

          <div>
            <ul className="list-inline font-13 text-end">
              <li className="list-inline-item">
                <Badge
                  bg=""
                  className={classNames(
                    "me-1",
                    "badge-outline-" + getStatusColor(task.status)
                  )}
                >
                  {task.status}
                </Badge>
              </li>

              <li className="list-inline-item">
                <i className="uil uil-schedule font-16 me-1"></i> {task.dueDate}
              </li>

              <li className="list-inline-item ms-2">
                <span
                  className={classNames(
                    "badge",
                    {
                      "badge-danger-lighten": task.priority === "high",
                      "badge-info-lighten": task.priority === "medium",
                      "badge-success-lighten": task.priority === "low",
                    },
                    "p-1"
                  )}
                >
                  {task.priority === "high" ? (
                    <i className="mdi mdi-arrow-up-bold" />
                  ) : task.priority === "low" ? (
                    <i className="mdi mdi-arrow-down-bold" />
                  ) : (
                    <i className="mdi mdi-arrow-left-right-bold" />
                  )}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </Col>
    </Row>
  );
};

const collapseStatusesKey = "collapse-statuses";

const TaskSection = ({
  tasks,
  selectTask,
  handleTaskCompleteEvent,
  isDraggable,
  reloadProject,
  sections,
  openTaskModal,
}) => {
  const { alertInfo } = useAlert();
  const { projectSectionService, taskService } = useHttp();
  const [currentTasks, setCurrentTasks] = useState([]);
  const [currentSections, setCurrentSections] = useState([]);

  useEffect(() => {
    const defaultSection = { id: 0, name: "Not Included by a Section" };
    let updatedSections = undefined;
    if (sections && sections.length > 0) {
      //TODO
      updatedSections = [defaultSection, ...sections];
    } else {
      updatedSections = [defaultSection];
    }

    setCurrentSections(updatedSections);
  }, [sections]);

  useEffect(() => {
    if (currentSections && currentSections.length > 0) {
      const localStatuses = getCollapseStatuses();
      const statuses = { ...localStatuses };
      currentSections.forEach((x) => {
        if (statuses[x.id] === undefined) {
          statuses[x.id] = true;
        }

        return statuses;
      });

      saveCollapseStatuses(statuses);
    }
  }, [currentSections]);

  useEffect(() => {
    const setTaskOrders = async () => {
      await makeDefaultOrder();
    };

    setTaskOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks]);

  useEffect(() => {
    if (!tasks) return;

    const list = [...tasks];
    const sortedList = isDraggable ? list.sort(compareTask) : list;

    setCurrentTasks(sortedList);
  }, [tasks, isDraggable]);

  const getCollapseStatuses = () => {
    const localStatuses = localStorage.getItem(collapseStatusesKey);
    return localStatuses ? JSON.parse(localStatuses) : {};
  };

  const saveCollapseStatuses = (statuses) => {
    localStorage.setItem(collapseStatusesKey, JSON.stringify(statuses));
  };

  const compareTask = (taskA, taskB) => {
    if (taskA.order > 0 && taskB.order > 0) {
      if (taskA.order < taskB.order) return -1;
      if (taskA.order > taskB.order) return 1;
      return 0;
    }

    if (taskA.id < taskB.id) return -1;
    if (taskA.id > taskB.id) return 1;
    return 0;
  };

  const sorter = async (list) => {
    const orderUpdateRequest = {
      projectId: list[0].projectId,
      tasks: list.map((task, index) => {
        return { taskId: task.id, order: index };
      }),
    };

    await taskService.updateOrder(orderUpdateRequest);
  };

  const makeDefaultOrder = async () => {
    if (!tasks || tasks.length <= 0) return;

    const latest = [];
    const notSectionedTasks = tasks.filter((x) => x.sectionId === 0);
    const sortedNotSectionedTasks = notSectionedTasks.sort(compareTask);
    latest.push(...sortedNotSectionedTasks);

    for (const section of sections) {
      const sectionTasks = tasks.filter((x) => x.sectionId === section.id);
      const sortedSectionTasks = sectionTasks.sort(compareTask);
      latest.push(...sortedSectionTasks);
    }

    await sorter(latest);

    setCurrentTasks(latest);
  };

  const toggleTasks = (id) => {
    const collapseStatuses = getCollapseStatuses();
    const statuses = { ...collapseStatuses };
    statuses[id] = !collapseStatuses[id];
    saveCollapseStatuses(statuses);
  };

  const reorder = async (startIndex, endIndex, newSection) => {
    const current = [...currentTasks];
    const [removed] = current.splice(startIndex, 1);

    if (newSection) {
      removed.sectionId = Number(newSection);
    }

    current.splice(
      newSection && endIndex > startIndex ? endIndex - 1 : endIndex,
      0,
      removed
    );

    sorter(current);

    setCurrentTasks(current);
  };

  const reorderSections = async (startIndex, endIndex) => {
    const current = Array.from(currentSections);
    const [removed] = current.splice(startIndex, 1);
    current.splice(endIndex, 0, removed);

    const newSectionOrder = current.map((section, index) => {
      return { sectionId: section.id, order: index };
    });

    newSectionOrder.splice(0, 1);

    const orderUpdateRequest = {
      projectId: current[1].projectId,
      sections: newSectionOrder,
    };

    projectSectionService.updateOrder(orderUpdateRequest);

    setCurrentSections(current);
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    if (
      result.reason === "DROP" &&
      result.source &&
      result.destination &&
      result.source.droppableId === "SECTIONS" &&
      result.destination.droppableId === "SECTIONS"
    ) {
      await reorderSections(result.source.index, result.destination.index);
      return;
    }

    let newSection = undefined;
    if (
      result.reason === "DROP" &&
      result.source &&
      result.destination &&
      result.source.droppableId !== result.destination.droppableId
    ) {
      if (Number(result.destination.droppableId) === 0) {
        alertInfo("task-can-not-be-moved-to-here");
        return;
      }

      taskService.updateSection(
        result.draggableId,
        result.destination.droppableId
      );

      newSection = result.destination.droppableId;
    }

    reorder(result.source.index, result.destination.index, newSection);
  };

  const deleteSection = async (id) => {
    await projectSectionService.deleteProjectSection(id);
    await reloadProject();
  };

  const collapseStatuses = getCollapseStatuses();

  const renderSection = (section, sectionTasks, i) => {
    return (
      <>
        {!(section.id === 0 && (!sectionTasks || sectionTasks.length <= 0)) && (
          <>
            <div className="mb-1 mt-1">
              <div className="row">
                <div className="col-md-auto h5">
                  <Link
                    className="text-dark"
                    to="#"
                    onClick={() => toggleTasks(section.id)}
                  >
                    <i
                      className={classNames(
                        "uil",
                        {
                          "uil-angle-down": collapseStatuses[section.id],
                          "uil-angle-right": !collapseStatuses[section.id],
                        },
                        "font-18"
                      )}
                    ></i>
                    {section.name}{" "}
                    <span className="text-muted">
                      ({sectionTasks ? sectionTasks.length : 0})
                    </span>
                  </Link>
                </div>
                <div className="col-md-auto">
                  {section.id > 0 && (
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip>Add Task to the Section</Tooltip>}
                    >
                      <Button
                        variant=""
                        onClick={() => openTaskModal(section.id)}
                      >
                        <i className={classNames("mdi", "mdi-plus")}></i>
                      </Button>
                    </OverlayTrigger>
                  )}
                  {section.id > 0 &&
                    (!sectionTasks || sectionTasks.length <= 0) && (
                      <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip>Delete Section</Tooltip>}
                      >
                        <Button
                          variant=""
                          onClick={() => deleteSection(section.id)}
                        >
                          <i
                            className={classNames(
                              "mdi",
                              "mdi-trash-can-outline"
                            )}
                          ></i>
                        </Button>
                      </OverlayTrigger>
                    )}
                </div>
              </div>
            </div>
            <Collapse in={collapseStatuses[section.id]}>
              <Card className="mb-0">
                <Card.Body className="pb-1 pt-2">
                  <Droppable droppableId={section.id?.toString()} type="task">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {sectionTasks && sectionTasks.length > 0 ? (
                          sectionTasks.map((task, index) => (
                            <Draggable
                              key={`d_${task.id}`}
                              draggableId={task.id.toString()}
                              index={currentTasks.findIndex(
                                (x) => x.id === task.id
                              )}
                            >
                              {(provided, snapshot) => (
                                <div
                                  className="me-2"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Task
                                    selectTask={selectTask}
                                    task={task}
                                    key={`dt_${task.id}`}
                                    handleTaskCompleteEvent={
                                      handleTaskCompleteEvent
                                    }
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))
                        ) : (
                          <>No task found in this section</>
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </Card.Body>
              </Card>
            </Collapse>
          </>
        )}
      </>
    );
  };

  const renderSections = () => {
    return (
      <div>
        {currentSections &&
          currentSections.length > 0 &&
          currentSections.map((section, i) => {
            const sectionTasks =
              currentTasks && currentTasks.length > 0
                ? currentTasks.filter((task) => task.sectionId === section.id)
                : [];

            if (section.id === 0) {
              return (
                <div key={"s_" + section.id + section.name + i}>
                  {renderSection(section, sectionTasks, i)}
                </div>
              );
            }

            return (
              <div key={"s_" + section.id + section.name + i}>
                <Draggable
                  key={`sectiion_d_${section.id}`}
                  draggableId={section.id.toString()}
                  index={currentSections.findIndex((x) => x.id === section.id)}
                >
                  {(provided, snapshot) => (
                    <div
                      className="me-2"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {renderSection(section, sectionTasks, i)}
                    </div>
                  )}
                </Draggable>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <>
      <>
        {isDraggable ? (
          <>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId={"SECTIONS"} type="section">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {renderSections()}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </>
        ) : (
          <>
            {currentSections &&
              currentSections.length > 0 &&
              currentSections.map((section, i) => {
                const sectionTasks =
                  tasks && tasks.length > 0
                    ? tasks.filter((task) => task.sectionId === section.id)
                    : [];

                return (
                  <div key={section.id + section.name + i}>
                    {!(
                      section.id === 0 &&
                      (!sectionTasks || sectionTasks.length <= 0)
                    ) && (
                      <>
                        <div className="mb-1 mt-1">
                          <div className="row">
                            <div className="col-md-auto h5">
                              <Link
                                className="text-dark"
                                to="#"
                                onClick={() => toggleTasks(section.id)}
                              >
                                <i
                                  className={classNames(
                                    "uil",
                                    {
                                      "uil-angle-down":
                                        collapseStatuses[section.id],
                                      "uil-angle-right":
                                        !collapseStatuses[section.id],
                                    },
                                    "font-18"
                                  )}
                                ></i>
                                {section.name}{" "}
                                <span className="text-muted">
                                  ({sectionTasks ? sectionTasks.length : 0})
                                </span>
                              </Link>
                            </div>
                          </div>
                        </div>
                        <Collapse in={collapseStatuses[section.id]}>
                          <Card className="mb-0">
                            <Card.Body className="pb-1 pt-2">
                              {sectionTasks && sectionTasks.length > 0 ? (
                                sectionTasks.map((task, index) => (
                                  <Task
                                    selectTask={selectTask}
                                    task={task}
                                    key={`dt_${task.id}`}
                                    handleTaskCompleteEvent={
                                      handleTaskCompleteEvent
                                    }
                                  />
                                ))
                              ) : (
                                <>No task found in this section</>
                              )}
                            </Card.Body>
                          </Card>
                        </Collapse>
                      </>
                    )}
                  </div>
                );
              })}
          </>
        )}
      </>
    </>
  );
};

export default TaskSection;
