import config from "../config/config";

const apiEndpoint = config.apiEndpoint + "/api/task-comment";

const taskCommentService = ({ get, post }) => {
  async function save(comment, taskId) {
    return post(apiEndpoint, { comment, taskId });
  }

  async function getAll(taskId) {
    return get(apiEndpoint + "?taskId=" + taskId);
  }

  return { save, getAll };
};

export default taskCommentService;
