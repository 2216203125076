// @flow
import React, { useEffect, useState } from "react";
import { Card, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

// components
import FileUploader from "../../../../components/FileUploader";

// images
import useHttp from "../../../../hooks/useHttp";

import avi from "../../../../assets/file-type-icons/avi.png";
import css from "../../../../assets/file-type-icons/css.png";
import csv from "../../../../assets/file-type-icons/csv.png";
import doc from "../../../../assets/file-type-icons/doc.png";
import exe from "../../../../assets/file-type-icons/exe.png";
import file from "../../../../assets/file-type-icons/file.png";
import html from "../../../../assets/file-type-icons/html.png";
import javascript from "../../../../assets/file-type-icons/javascript.png";
import json from "../../../../assets/file-type-icons/json-file.png";
import mp3 from "../../../../assets/file-type-icons/mp3.png";
import mp4 from "../../../../assets/file-type-icons/mp4.png";
import pdf from "../../../../assets/file-type-icons/pdf.png";
import ppt from "../../../../assets/file-type-icons/ppt.png";
import psd from "../../../../assets/file-type-icons/psd.png";
import svg from "../../../../assets/file-type-icons/svg.png";
import txt from "../../../../assets/file-type-icons/txt.png";
import xls from "../../../../assets/file-type-icons/xls.png";
import xml from "../../../../assets/file-type-icons/xml.png";
import zip from "../../../../assets/file-type-icons/zip.png";

const Attachments = (props) => {
  const { attachmentService } = useHttp();
  const [attachments, setAttachments] = useState();

  useEffect(() => {
    setAttachments(props.attachments);
  }, [props.attachments]);

  /*
   * handle file upload
   */
  const handleAttachments = async (file) => {
    const request = {
      attachment: file,
      data: JSON.stringify({
        projectId: props.projectId,
        taskId: props.taskId,
        explanation: "x",
      }),
    };

    const newAttach = await attachmentService.save(request);

    const atts = [newAttach.data.success, ...attachments];
    setAttachments(atts);
  };

  const handleDownload = async (url) => {
    if (url) {
      window.open(url, "_blank");
    }
  };

  const handleRemove = async (id) => {
    await attachmentService.remove(id);

    const atts = [...attachments];
    const i = atts.findIndex((x) => x.id === id);
    if (i > -1) atts.splice(i, 1);
    setAttachments(atts);
  };

  const getIcon = (ext) => {
    switch (ext) {
      case "avi":
        return avi;
      case "css":
        return css;
      case "csv":
        return csv;
      case "doc":
        return doc;
      case "docx":
        return doc;
      case "exe":
        return exe;
      case "html":
        return html;
      case "js":
        return javascript;
      case "json":
        return json;
      case "mp3":
        return mp3;
      case "mp4":
        return mp4;
      case "pdf":
        return pdf;
      case "ppt":
        return ppt;
      case "psd":
        return psd;
      case "svg":
        return svg;
      case "txt":
        return txt;
      case "xls":
        return xls;
      case "xlsx":
        return xls;
      case "xml":
        return xml;
      case "zip":
        return zip;

      default:
        return file;
    }
  };

  return (
    <>
      <Card>
        <Card.Body>
          <h5 className="card-title mb-3">Attachments</h5>
          <FileUploader onFileUpload={handleAttachments} showPreview={false} />
          <br />
          {attachments &&
            attachments.length > 0 &&
            attachments.map((attachment) => {
              const i = attachment.name.lastIndexOf(".");
              const ext = attachment.name.substring(i + 1);
              const icon = getIcon(ext);
              return (
                <div key={`attachment_${attachment.id}`}>
                  <Card className="mb-1 shadow-none border">
                    <div className="p-2">
                      <Row className="align-items-center">
                        <div className="col-auto">
                          <img
                            src={
                              [
                                "jpg",
                                "jpeg",
                                "png",
                                "gif",
                                "tiff",
                                "webp",
                                "avif",
                                "svg",
                              ].includes(ext)
                                ? attachment.url
                                : icon
                            }
                            className="avatar-sm rounded"
                            alt=""
                          />
                        </div>
                        <div className="col ps-0">
                          <Link to="#" className="text-muted fw-bold">
                            {attachment.name.slice(0, 20)}
                          </Link>
                          <p className="mb-0">
                            {Number(attachment.size / 1000000).toFixed(2)} MB
                          </p>
                        </div>
                        <div className="col-auto">
                          <div className="col-auto">
                            <Link
                              to="#"
                              className="btn btn-link btn-lg text-muted"
                            >
                              <i
                                className="dripicons-cross"
                                onClick={() => handleRemove(attachment.id)}
                              ></i>
                            </Link>
                          </div>
                          <div className="col-auto">
                            <Link
                              to="#"
                              className="btn btn-link btn-lg text-muted"
                            >
                              <i
                                className="dripicons-download"
                                onClick={() => handleDownload(attachment.url)}
                              ></i>
                            </Link>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </Card>
                </div>
              );
            })}
        </Card.Body>
      </Card>
    </>
  );
};

export default Attachments;
