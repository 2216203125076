import config from "../config/config";
import { getCurrentUser } from "./authService";

const apiEndpoint = config.apiEndpoint + "/api/project";

const projectService = ({ get, post }) => {
  async function getProjectList(projectStatus) {
    const projectResponse = get(
      apiEndpoint +
        "?teamId=" +
        getCurrentUser().teams[0].teamId +
        "&projectStatus=" +
        projectStatus ?? ""
    );

    return projectResponse;
  }

  async function createProject(newProject) {
    return post(apiEndpoint, newProject);
  }

  async function getProject(projectId) {
    return get(apiEndpoint + "/single?projectId=" + projectId);
  }

  async function completeProject(projectId) {
    return post(apiEndpoint + "/complete", projectId);
  }

  async function deleteProject(projectId) {
    return post(apiEndpoint + "/delete", projectId);
  }

  async function updateProject(project) {
    return post(apiEndpoint + "/update", project);
  }

  async function updateProjectStatus(project) {
    return post(apiEndpoint + "/status", project);
  }

  return {
    getProjectList,
    createProject,
    getProject,
    completeProject,
    deleteProject,
    updateProject,
    updateProjectStatus,
  };
};

export default projectService;
