// @flow
import React from "react";
import { getTimeText } from "../../helpers/date";
import CommentEntry from "./CommentEntry";

const TaskComment = ({ comments, onSave, taskId }) => {
  return (
    <>
      <h5 className="mb-2 font-16">Comments</h5>

      {comments.map((comment, idx) => (
        <React.Fragment key={idx}>
          <div key={idx} className="d-flex mt-3 p-1">
            {/* <img
                    src={comment.author_avatar}
                    className="me-2 rounded-circle"
                    height="36"
                    alt={comment.author}
                /> */}
            <div className="w-100">
              <h5 className="mt-0 mb-0">
                <span className="float-end text-muted font-12">
                  {getTimeText(comment.createdAt)}
                </span>
                {comment.User.name} {comment.User.surname}
              </h5>
              <p className="mt-1 mb-0 text-muted">{comment.comment}</p>
            </div>
          </div>
          <hr />
        </React.Fragment>
      ))}

      <CommentEntry type="task" recordId={taskId} onSave={onSave} />
    </>
  );
};

export default TaskComment;
