import config from "../config/config";

const apiEndpoint = config.apiEndpoint + "/api/project-comment";

const projectCommentService = ({ get, post }) => {
  async function save(comment, projectId) {
    return post(apiEndpoint, { comment, projectId });
  }

  async function getAll(projectId) {
    return get(apiEndpoint + "?projectId=" + projectId);
  }

  return { save, getAll };
};

export default projectCommentService;
