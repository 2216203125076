// @flow
import React from "react";
import Chart from "react-apexcharts";
import { Card } from "react-bootstrap";

const SalesChart = ({ data }) => {
  const apexDonutOpts = {
    chart: {
      height: 340,
      type: "donut",
    },
    colors: ["#727cf5", "#0acf97", "#ffbc00"],
    legend: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 376,
        options: {
          chart: {
            width: 250,
            height: 250,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const apexDonutData = [
    data?.inProgressProjectCount || 0,
    data?.deployedProjectCount || 0,
    data?.biddingProjectCount || 0,
  ];

  return (
    <Card>
      <Card.Body>
        <h4 className="header-title">Ongoing Projects</h4>

        <Chart
          options={apexDonutOpts}
          series={apexDonutData}
          type="donut"
          height={236}
          className="apex-charts mb-4 mt-4"
        />

        <div className="chart-widget-list">
          <p>
            <i className="mdi mdi-square text-primary"></i> In-Progress
            <span className="float-end">
              {data?.inProgressProjectBudget} M/D
            </span>
          </p>
          <p>
            <i className="mdi mdi-square text-success"></i> Deployed
            <span className="float-end">{data?.deployedProjectBudget} M/D</span>
          </p>
          <p className="mb-0">
            <i className="mdi mdi-square text-warning"></i> Bidding
            <span className="float-end">{data?.biddingProjectBudget} M/D</span>
          </p>
        </div>
      </Card.Body>
    </Card>
  );
};

export default SalesChart;
