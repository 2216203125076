import config from "../config/config";
import { subtractDays } from "../helpers/date";
import { getCurrentUser } from "./authService";

const apiEndpoint = config.apiEndpoint + "/api/sprint";

const sprintService = ({ get, post }) => {
  async function getList() {
    const startDate = subtractDays(new Date(), 60);

    const projectResponse = get(
      apiEndpoint +
        "?teamId=" +
        getCurrentUser().teams[0].teamId +
        "&startDate=" +
        startDate.toISOString()
    );

    return projectResponse;
  }

  async function create(data) {
    return post(apiEndpoint, data);
  }

  async function update(data) {
    return post(apiEndpoint + "/update", data);
  }

  async function remove(id) {
    return post(apiEndpoint + "/remove", { id });
  }

  return {
    getList,
    create,
    update,
    remove,
  };
};

export default sprintService;
