// @flow
import React from "react";
import * as yup from "yup";
import { Link } from "react-router-dom";
import useHttp from "../../hooks/useHttp";
import { yupResolver } from "@hookform/resolvers/yup";
import VerticalForm from "../VerticalForm";
import FormInput from "../FormInput";
const CommentEntry = ({ type, onSave, recordId }) => {
  const { taskCommentService, projectCommentService } = useHttp();

  const onSubmit = async (formData) => {
    const { comment } = formData;
    let result = null;
    if (type === "task") {
      await taskCommentService.save(comment, recordId);
      result = await taskCommentService.getAll(recordId);
    } else {
      result = await projectCommentService.save(comment, recordId);
      result = await projectCommentService.getAll(recordId);
    }

    if (result?.data?.isError === false) {
      console.log("result.data.seucces", result.data.success);
      onSave(result.data.success);
    }
  };

  const schemaResolver = yupResolver(
    yup.object().shape({
      comment: yup
        .string()
        .min(2)
        .max(1000)
        .required("Please enter time you spent in hours"),
    })
  );

  return (
    <>
      <div className="border rounded">
        <VerticalForm
          onSubmit={onSubmit}
          resolver={schemaResolver}
          defaultValues={{ workLog: "" }}
        >
          <FormInput
            type="textarea"
            rows="3"
            name="comment"
            placeholder="Your comment..."
            className="form-control border-0 resize-none"
            key="comment"
          />

          <div className="p-2 bg-light">
            <div className="float-end">
              <button type="submit" className="btn btn-sm btn-success">
                <i className="uil uil-message me-1"></i>Submit
              </button>
            </div>
            <div>
              <Link to="#" className="btn btn-sm px-1 btn-light">
                <i className="uil uil-cloud-upload"></i>
              </Link>
              <Link to="#" className="btn btn-sm px-1 btn-light">
                <i className="uil uil-at"></i>
              </Link>
            </div>
          </div>
        </VerticalForm>
      </div>
    </>
  );
};

export default CommentEntry;
