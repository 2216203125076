import { Badge, Card, ProgressBar } from "react-bootstrap";

const ProjectSummary = ({ data }) => {
  const getCompletedProjectRate = () => {
    if (!data?.completedProjectCount || !data?.totalProjectCount) {
      return 0;
    }

    return (data?.completedProjectCount / data?.totalProjectCount) * 100;
  };

  const getOverdueProjectRate = () => {
    if (!data?.overdueProjectCount || !data?.inProgressProjectCount) {
      return 0;
    }

    return (data?.overdueProjectCount / data?.inProgressProjectCount) * 100;
  };

  return (
    <>
      <Card>
        <Card.Body>
          <h4 className="fw-semibold mt-0 mb-3">
            Overdue Project Count
            <Badge bg="danger-lighten" className="text-danger fw ms-sm-1">
              <i className="mdi mdi-calendar-alert me-1"></i>
              {data?.overdueProjectCount}
            </Badge>
          </h4>
          <h5 className="float-end mt-0">
            {getOverdueProjectRate().toFixed(2)}%
          </h5>
          <h5 className="fw-normal mt-0">Overdue Projects</h5>
          <ProgressBar
            now={getOverdueProjectRate()}
            style={{ height: 3 }}
            variant="danger"
          />
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <h4 className="fw-semibold mt-0 mb-3">
            On Time Completed Rate
            <Badge bg="primary-lighten" className="text-primary fw ms-sm-1">
              <i className="mdi mdi-trending-up me-1"></i>
              {data?.completedOnTimeProjectRate}%
            </Badge>
          </h4>
          <h5 className="float-end mt-0">
            {getCompletedProjectRate().toFixed(2)}%
          </h5>
          <h5 className="fw-normal mt-0">Completed Projects</h5>
          <ProgressBar now={getCompletedProjectRate()} style={{ height: 3 }} />
        </Card.Body>
      </Card>
    </>
  );
};

export default ProjectSummary;
