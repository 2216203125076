import config from "../config/config";

const apiEndpoint = config.apiEndpoint + "/api/project-section";

const projectSectionService = ({ post }) => {
  async function createProjectSection(newProjectSection) {
    return post(apiEndpoint, newProjectSection);
  }

  async function deleteProjectSection(projectSectionId) {
    return post(apiEndpoint + `/delete`, { projectSectionId });
  }

  async function updateOrder(order) {
    return post(apiEndpoint + `/order`, order);
  }

  return { createProjectSection, deleteProjectSection, updateOrder };
};

export default projectSectionService;
