import config from "../config/config";
import { getCurrentUser } from "./authService";

const apiEndpoint = config.apiEndpoint + "/api/team-setting";

const teamSettingService = ({ get, post }) => {
  async function save(settingKey, settingValue) {
    const teamId = getCurrentUser().teams[0].teamId;
    return post(apiEndpoint, {
      teamId: teamId,
      settingKey: settingKey,
      settingValue: settingValue,
    });
  }

  async function getAll() {
    const teamId = getCurrentUser().teams[0].teamId;
    return get(apiEndpoint + "/all?teamId=" + teamId);
  }

  return { save, getAll };
};

export default teamSettingService;
