// @flow
import React from "react";
import { Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ProjectStatus } from "../../../core/project-status";
import { corp } from "../../../helpers/string";
import { getFutureTimeText } from "../../../helpers/date";

const LatestProjects = ({ data }) => {
  return (
    <Card>
      <Card.Body>
        <h4 className="header-title mb-3">Latest Projects</h4>

        <Table responsive hover className="table-centered table-nowrap mb-0">
          <tbody>
            {data?.lastCreatedProjects &&
              data?.lastCreatedProjects.map((project, index) => (
                <tr key={index}>
                  <td>
                    <h5 className="font-14 my-1">
                      <Link
                        to={`apps/projects/details/${project.id}`}
                        className="text-body"
                      >
                        {corp(project.name, 50)}
                      </Link>
                    </h5>
                    <span className="text-muted font-13">
                      {getFutureTimeText(project.dueDate)}
                    </span>
                  </td>
                  <td>
                    <span className="text-muted font-13">Status</span> <br />
                    <span
                      className={`badge badge-${
                        ProjectStatus[project.status].color
                      }-lighten`}
                    >
                      {ProjectStatus[project.status].name}
                    </span>
                  </td>
                  <td>
                    <span className="text-muted font-13">Assigned to</span>
                    <h5 className="font-14 mt-1 fw-normal">
                      {["analysis", "bid", "budget"].includes(project.status) &&
                        project.al?.name + " " + project.al?.surname}
                      {!["analysis", "bid", "budget"].includes(
                        project.status
                      ) && project.sl?.name + " " + project.sl?.surname}
                    </h5>
                  </td>
                  <td>
                    <span className="text-muted font-13">Total Budget</span>
                    <h5 className="font-14 mt-1 fw-normal">
                      {project.budget} M/D
                    </h5>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default LatestProjects;
