export function shorten(text, max) {
  if (!text || text.length <= max) return text;

  return text.substring(0, max - 1) + "...";
}

export function corp(text, max) {
  if (!text || text.length <= max) return text;

  return text.substring(0, max - 1);
}

export function formatString(format, ...args) {
  return format.replace(/{(\d+)}/g, (match, index) => {
    return typeof args[index] !== "undefined" ? args[index] : match;
  });
}
