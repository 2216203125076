import config from "../config/config";
import { getCurrentUser } from "./authService";

const apiEndpoint = config.apiEndpoint + "/api/project-dashboard";

const projectDashboardService = ({ get }) => {
  async function getData() {
    return get(apiEndpoint + "?teamId=" + getCurrentUser().teams[0].teamId);
  }

  return { getData };
};

export default projectDashboardService;
