import React from "react";
import ReactDOM from "react-dom";

import "./i18n";

import App from "./App";

import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import { AlertProvider } from "./hooks/useAlert";

ReactDOM.render(
  <Provider store={configureStore({})}>
    <AlertProvider>
      <App />
    </AlertProvider>
  </Provider>,
  document.getElementById("root")
);
