import config from "../config/config";

const apiEndpoint = config.apiEndpoint + "/api/attachment";

const attachmentService = ({ get, post }) => {
  async function save(attachment) {
    const formData = new FormData();

    for (const k in attachment) {
      if (Array.isArray(attachment[k])) {
        for (let j in attachment[k]) {
          formData.append(k, attachment[k][j]);
        }
      } else {
        formData.append(k, attachment[k]);
      }
    }

    const headers = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    return post(apiEndpoint, formData, { ...headers });
  }

  async function remove(id) {
    return post(apiEndpoint + `/delete/${id}`);
  }

  async function download(id) {
    return get(apiEndpoint + `/download/${id}`);
  }

  return { save, remove, download };
};

export default attachmentService;
