import { Dropdown } from "react-bootstrap";
import { TaskProps, notCompletedStatuses } from "../core/task-status";
import { useEffect, useState } from "react";

export const statusFiltersKey = "status-filters";

const StatusFilterDropdown = ({ filterTasks }) => {
  const [statusFilterText, setStatusFilterText] = useState("");

  useEffect(() => {
    setStatusFilterText(getStatusFilterText());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStatusFilters = () => {
    const localStatuses = localStorage.getItem(statusFiltersKey);
    return localStatuses ? JSON.parse(localStatuses) : [];
  };

  const getStatusFilterText = () => {
    const filters = getStatusFilters();
    if (!filters || filters.length === 0) {
      return "All";
    }

    if (isNotCompletedSelected()) {
      return "Not Completed";
    }

    return filters.length === 1 ? TaskProps[filters[0]].name : "Customized";
  };

  const statusFilters = getStatusFilters();

  const saveStatusFilters = (filters) => {
    localStorage.setItem(statusFiltersKey, JSON.stringify(filters));
    const filterText = getStatusFilterText();
    setStatusFilterText(filterText);
    filterTasks();
  };

  const isNotCompletedSelected = () => {
    const statusFilters = getStatusFilters();
    if (statusFilters.length !== notCompletedStatuses.length) return false;

    let result = true;
    for (const status of notCompletedStatuses) {
      if (!statusFilters.includes(status)) {
        result = false;
      }
    }

    return result;
  };

  const filterButtonClick = (status) => {
    let newStatuses = [];
    if (status === "all") {
      saveStatusFilters([]);
      return;
    }

    if (status === "not-completed") {
      saveStatusFilters(notCompletedStatuses);
      return;
    }

    if (isNotCompletedSelected()) {
      saveStatusFilters([status]);
      return;
    }

    const statusFilters = getStatusFilters();
    const index = statusFilters.indexOf(status);
    if (index !== -1) {
      newStatuses = [...statusFilters];
      newStatuses.splice(index, 1);
    } else {
      newStatuses = [...statusFilters, status];
    }

    if (Object.keys(TaskProps).length === newStatuses.length) {
      newStatuses = [];
    }

    saveStatusFilters(newStatuses);
  };

  return (
    <Dropdown className="d-inline-block me-1 my-1" autoClose="outside">
      <Dropdown.Toggle className="btn btn-secondary dropdown-toggle arrow-none">
        <i className="mdi mdi-list-status me-2"></i>
        <span>{statusFilterText}</span>
        <i className="mdi mdi-chevron-down ms-1"></i>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => filterButtonClick("all")}>
          <div className="constainer">
            <div className="row justify-content-between">
              <div className="col-6">
                <span>All</span>
              </div>
              {Object.keys(TaskProps).length === statusFilters.length ||
                (statusFilters.length === 0 && (
                  <div className="col-3">
                    <i className="mdi mdi-check"></i>
                  </div>
                ))}
            </div>
          </div>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => filterButtonClick("not-completed")}>
          <div className="constainer">
            <div className="row justify-content-between">
              <div className="col-6">
                <span>Not Completed</span>
              </div>
              {isNotCompletedSelected() && (
                <div className="col-3">
                  <i className="mdi mdi-check"></i>
                </div>
              )}
            </div>
          </div>
        </Dropdown.Item>
        <Dropdown.Divider />
        {Object.keys(TaskProps).map((x, i) => {
          return (
            <Dropdown.Item onClick={() => filterButtonClick(x)} key={i}>
              <div className="constainer">
                <div className="row justify-content-between">
                  <div className="col-6">
                    <span>{TaskProps[x].name}</span>
                  </div>
                  {statusFilters.includes(x) &&
                    Object.values(TaskProps).length !== statusFilters.length &&
                    !isNotCompletedSelected() && (
                      <div className="col-3">
                        <i className="mdi mdi-check"></i>
                      </div>
                    )}
                </div>
              </div>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default StatusFilterDropdown;
