// @flow
import React, { useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import "@fullcalendar/react";
import classNames from "classnames";

import Calendar from "../../Calendar/Calendar";
import AddEditProjectEvent from "./AddEditProjectEvent";
import useHttp from "../../../../hooks/useHttp";

const SidePanel = () => {
  // external events
  const externalEvents = [
    {
      id: 1,
      textClass: "text-success",
      className: "bg-success",
      title: "Project Deadline",
    },
    {
      id: 2,
      textClass: "text-primary",
      className: "bg-primary",
      title: "Project Start",
    },
  ];

  return (
    <>
      <div id="external-events" className="m-t-20">
        <br />
        <p className="text-muted">
          Project Deadline and Project Start are automatically added
        </p>
        {/* external events */}
        {externalEvents.map((event, index) => {
          return (
            <div
              key={index}
              className={classNames(
                "external-event",
                event.className + "-lighten",
                event.textClass
              )}
              title={event.title}
              data={event.className}
            >
              <i className="mdi mdi-checkbox-blank-circle me-2 vertical-middle"></i>
              {event.title}
            </div>
          );
        })}
      </div>

      <div className="mt-5 d-none d-xl-block">
        <h5 className="text-center">How It Works ?</h5>

        <ul className="ps-3">
          <li className="text-muted mb-3">
            You must edit project start and due date from project detail page to
            see the changes here.
          </li>
          <li className="text-muted mb-3">UAT Dates will be added soon.</li>
        </ul>
      </div>
    </>
  );
};

const ProjectCalendar = (state) => {
  const { projectService } = useHttp();
  /*
   * modal handeling
   */
  const [show, setShow] = useState(false);
  const onCloseModal = () => {
    setShow(false);
    setEventData({});
    setDateInfo({});
  };
  const onOpenModal = () => setShow(true);
  const [isEditable, setIsEditable] = useState(false);

  /*
   * event data
   */
  const [events, setEvents] = useState([]);
  const [eventData, setEventData] = useState({});
  const [dateInfo, setDateInfo] = useState({});

  useEffect(() => {
    async function GetProjects() {
      const apiProjectsRes = await projectService.getProjectList("");
      const apiProjects = apiProjectsRes.data.success;

      const projectEvents = [];
      apiProjects?.forEach((pr) => {
        projectEvents.push({
          id: pr.id + "start",
          title: pr.name + "Project Start",
          start: pr.startDate,
          end: pr.startDate,
          className: "bg-primary",
        });

        projectEvents.push({
          id: pr.id + "due",
          title: pr.name + "Project Due",
          start: pr.dueDate,
          end: pr.dueDate,
          className: "bg-success",
        });
      });

      setEvents(projectEvents);
    }
    GetProjects();
    // eslint-disable-next-line
  }, []);

  /*
    calendar events
    */
  // on date click
  const onDateClick = (arg) => {
    setDateInfo(arg);
    onOpenModal();
    setIsEditable(false);
  };

  // on event click
  const onEventClick = (arg) => {
    const event = {
      id: parseInt(arg.event.id),
      title: arg.event.title,
      start: arg.event.start,
      className: arg.event.classNames[0],
    };
    setEventData(event);
    onOpenModal();
    setIsEditable(true);
  };

  // on drop
  const onDrop = (arg) => {
    const dropEventData = arg;
    const title = dropEventData.draggedEl.title;
    if (title == null) {
    } else {
      let newEvent = {
        id: events.length + 1,
        title: title,
        start: dropEventData ? dropEventData.dateStr : new Date(),
        className: dropEventData.draggedEl.attributes.data.value,
      };
      const modifiedEvents = [...events];
      modifiedEvents.push(newEvent);

      setEvents(modifiedEvents);
    }
  };

  /*
    on add event 
    */
  const onAddEvent = (data) => {
    const modifiedEvents = [...events];
    const event = {
      id: modifiedEvents.length + 1,
      title: data.title,
      start: dateInfo ? dateInfo.date : new Date(),
      className: data.className,
    };
    modifiedEvents.push(event);
    setEvents(modifiedEvents);
    onCloseModal();
  };

  /*
    on update event
    */
  const onUpdateEvent = (data) => {
    const modifiedEvents = [...events];
    const idx = modifiedEvents.findIndex((e) => e["id"] === eventData.id);
    modifiedEvents[idx]["title"] = data.title;
    modifiedEvents[idx]["className"] = data.className;
    setEvents(modifiedEvents);
    onCloseModal();
  };

  /*
    on remove event
    */
  const onRemoveEvent = () => {
    var modifiedEvents = [...events];
    const idx = modifiedEvents.findIndex((e) => e["id"] === eventData.id);
    modifiedEvents.splice(idx, 1);
    setEvents(modifiedEvents);
    onCloseModal();
  };

  /**
   * on event drop
   */
  const onEventDrop = (arg) => {
    const modifiedEvents = [...events];
    const idx = modifiedEvents.findIndex(
      (e) => e["id"] === Number(arg.event.id)
    );
    modifiedEvents[idx]["title"] = arg.event.title;
    modifiedEvents[idx]["className"] = arg.event.classNames;
    modifiedEvents[idx]["start"] = arg.event.start;
    modifiedEvents[idx]["end"] = arg.event.end;
    setEvents(modifiedEvents);
    setIsEditable(false);
  };

  return (
    <>
      <Row className="mt-2">
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Col xl={3}>
                  <div className="d-grid"></div>

                  <SidePanel />
                </Col>
                <Col xl={9}>
                  {/* fullcalendar control */}
                  <Calendar
                    onDateClick={onDateClick}
                    onEventClick={onEventClick}
                    onDrop={onDrop}
                    onEventDrop={onEventDrop}
                    events={events}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* add new event modal */}
      {show ? (
        <AddEditProjectEvent
          isOpen={show}
          onClose={onCloseModal}
          isEditable={isEditable}
          eventData={eventData}
          onUpdateEvent={onUpdateEvent}
          onRemoveEvent={onRemoveEvent}
          onAddEvent={onAddEvent}
        />
      ) : null}
    </>
  );
};

export default ProjectCalendar;
