export const TaskProps = {
  "not-started": {
    color: "primary",
    name: "Not Started",
  },
  "in-progress": {
    color: "info",
    name: "In Progress",
  },
  completed: {
    color: "success",
    name: "Completed",
  },
  "on-hold": {
    color: "warning",
    name: "On Hold",
  },
  testing: {
    color: "secondary",
    name: "Testing",
  },
  "waiting-test": {
    color: "dark",
    name: "Waiting Test",
  },
};

export const notCompletedStatuses = [
  "in-progress",
  "not-started",
  "on-hold",
  "testing",
  "waiting-test",
];
