// @flow
import React, { useState, useEffect } from "react";
import { Table, Button, Row, Col, Modal } from "react-bootstrap";
import classNames from "classnames";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import useHttp from "../../hooks/useHttp";
import FormInput from "../FormInput";
import VerticalForm from "../VerticalForm";

const WorkLogTable = ({ workLogs, taskId }) => {
  const { workLogService } = useHttp();
  const [workLogItems, setWorkLogs] = useState(workLogs);
  const [isCreateVisible, setIsCreateVisible] = useState(false);

  useEffect(() => {
    setWorkLogs(workLogs);
  }, [workLogs, taskId]);

  const handleDelete = async (workLogId) => {
    await workLogService.deleteLog(workLogId);
    await refreshWorkLogs();
  };

  const handleCreate = async (formData) => {
    await workLogService.create({ taskId: taskId, workLog: formData.workLog });

    setIsCreateVisible(false);
    await refreshWorkLogs();
  };

  const refreshWorkLogs = async () => {
    const response = await workLogService.getTaskLogs(taskId);
    setWorkLogs(response.data.success);
  };

  const toggleTaskCompleteModal = () => {
    setIsCreateVisible(!isCreateVisible);
  };

  const schemaResolver = yupResolver(
    yup.object().shape({
      workLog: yup
        .number()
        .positive()
        .required("Please enter time you spent in hours"),
    })
  );

  return (
    <>
      <Row>
        <Col>
          <p className="mt-2 mb-2 text-muted">Work Logs</p>
        </Col>
        <Col>
          <div className="mb-2" style={{ marginRight: "8px" }}>
            <Button
              variant={"outline-primary"}
              className="btn-icon"
              style={{ height: 35, float: "right" }}
              onClick={toggleTaskCompleteModal}
            >
              <i className="mdi mdi-plus"></i>
            </Button>
          </div>
        </Col>
      </Row>

      <Table responsive className="table table-sm table-centered mb-0 font-14">
        <thead className="table-light">
          <tr>
            <th>Employee</th>
            <th>Duration (Hours)</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {workLogItems &&
            workLogItems.map((work, index) => {
              return (
                <tr key={index}>
                  <td>
                    {work.User.name} {work.User.surname}
                  </td>
                  <td className="text-center">{work.time}</td>
                  <td>
                    <Button
                      variant={"danger"}
                      className="btn-icon"
                      style={{ height: 35, float: "right" }}
                      onClick={() => handleDelete(work.id)}
                    >
                      <i className="mdi mdi-trash-can-outline"></i>
                    </Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>

      <Modal show={isCreateVisible} onHide={toggleTaskCompleteModal}>
        <Modal.Header
          onHide={toggleTaskCompleteModal}
          closeButton
          className={classNames("modal-colored-header", "bg-primary")}
        >
          <h4 className="modal-title">Enter Your WorkLog</h4>
        </Modal.Header>
        <Modal.Body>
          <VerticalForm
            onSubmit={handleCreate}
            resolver={schemaResolver}
            defaultValues={{ workLog: "" }}
          >
            <FormInput
              name="workLog"
              label="Please type hour you spent on this task"
              placeholder="Enter hour"
              type="number"
            />

            <div className="mb-3 mb-0 mt-2 text-center">
              <Button variant="primary" type="submit">
                Create
              </Button>
            </div>
          </VerticalForm>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WorkLogTable;
