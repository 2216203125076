export const getToday = () => {
  return toDateFormat(new Date());
};

export const getStartOfNextWeek = () => {
  var curr = new Date();
  var nextweek = curr.getDate() - curr.getDay() + 8;
  return toDateFormat(new Date(curr.setDate(nextweek)));
};

export const toDateFormat = (date) => {
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  const yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return yyyy + "-" + mm + "-" + dd;
};

export const addDays = (date, days) => {
  var newDate = new Date(date);
  newDate.setDate(date.getDate() - days);
  return newDate;
};
