// @flow
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import Dropzone from "react-dropzone";

const FileUploader = (props) => {
  const [selectedFile, setSelectedFile] = useState();

  /**
   * Handled the accepted files and shows the preview
   */
  const handleAcceptedFiles = (files) => {
    var file = files[0];
    if (!file) return;

    if (props.showPreview) {
      Object.assign(file, {
        preview:
          file["type"].split("/")[0] === "image"
            ? URL.createObjectURL(file)
            : null,
        formattedSize: formatBytes(file.size),
      });

      setSelectedFile(file);
    }

    if (props.onFileUpload) props.onFileUpload(file);
  };

  /**
   * Formats the size
   */
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  /*
   * Removes the selected file
   */
  const removeFile = (file) => {
    setSelectedFile(undefined);
  };

  return (
    <>
      <Dropzone
        {...props}
        onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
      >
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone">
            <div className="dz-message needsclick" {...getRootProps()}>
              <input {...getInputProps()} />
              <i className="h3 text-muted dripicons-cloud-upload"></i>
              <h5>Drop files here or click to upload.</h5>
              <span className="text-muted font-13">
                (This is just a demo dropzone. Selected files are{" "}
                <strong>not</strong> actually uploaded.)
              </span>
            </div>
          </div>
        )}
      </Dropzone>

      {props.showPreview && selectedFile && (
        <div className="dropzone-previews mt-3" id="uploadPreviewTemplate">
          <Card className="mt-1 mb-0 shadow-none border" key={"selected-file"}>
            <div className="p-2">
              <Row className="align-items-center">
                {selectedFile.preview && (
                  <Col className="col-auto">
                    <img
                      data-dz-thumbnail=""
                      className="avatar-sm rounded bg-light"
                      alt={selectedFile.name}
                      src={selectedFile.preview}
                    />
                  </Col>
                )}
                {!selectedFile.preview && (
                  <Col className="col-auto">
                    <div className="avatar-sm">
                      <span className="avatar-title bg-primary rounded">
                        {selectedFile.type.split("/")[0]}
                      </span>
                    </div>
                  </Col>
                )}
                <Col className="ps-0">
                  <Link to="#" className="text-muted fw-bold">
                    {selectedFile.name}
                  </Link>
                  <p className="mb-0">
                    <strong>{selectedFile.formattedSize}</strong>
                  </p>
                </Col>
                <Col className="text-end">
                  <Link
                    to="#"
                    className="btn btn-link btn-lg text-muted shadow-none"
                  >
                    <i
                      className="dripicons-cross"
                      onClick={() => removeFile()}
                    ></i>
                  </Link>
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      )}
    </>
  );
};

FileUploader.defaultProps = {
  showPreview: true,
};

export default FileUploader;
