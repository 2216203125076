import { Card } from "react-bootstrap";
import Chart from "react-apexcharts";
import CardTitle from "../../../components/CardTitle";

const ManDayStatistics = ({ data }) => {
  const apexOpts = {
    chart: {
      height: 364,
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "25%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 3,
      colors: ["transparent"],
    },
    colors: ["#ced1ff", "#727cf5"],
    xaxis: {
      //   categories: [
      //     "Feb",
      //     "Mar",
      //     "Apr",
      //     "May",
      //     "Jun",
      //     "Jul",
      //     "Aug",
      //     "Sep",
      //     "Oct",
      //   ],
      categories: Object.keys(data?.bidProjectByMonth || {}),
    },
    legend: {
      offsetY: 9,
    },
    yaxis: {
      title: {
        text: "Man Day",
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      row: {
        colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.2,
      },
      borderColor: "#f1f3fa",
      padding: {
        bottom: 3,
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " M/D";
        },
      },
    },
  };

  // chart data
  const series = [
    {
      name: "Total Bid",
      data: Object.values(data?.bidProjectByMonth || {}), //[44, 55, 57, 56, 61, 58, 63, 60, 66],
    },
    {
      name: "Completed Project",
      data: Object.values(data?.completedProjectByMonth || {}),
    },
  ];

  return (
    <Card>
      <Card.Body>
        <CardTitle
          containerClass="d-flex align-items-center justify-content-between"
          title="Monthly M/D"
        />
        <div style={{ height: "364px" }} className="mt-3 chartjs-chart">
          <Chart
            options={apexOpts}
            series={series}
            type="bar"
            height={364}
            className="apex-charts"
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default ManDayStatistics;
