import config from "../config/config";

const apiEndpoint = config.apiEndpoint + "/api/notification";

const notificationService = ({ get, post }) => {
  async function getUnreadNotificationList() {
    return get(apiEndpoint + "/unread");
  }

  async function readAllNotifications() {
    return post(apiEndpoint + "/readAll");
  }

  return { getUnreadNotificationList, readAllNotifications };
};

export default notificationService;
