import config from "../config/config";

const apiEndpoint = config.apiEndpoint + "/api/member";

const authService = ({ get, post }) => {
  async function login(email, password) {
    return post(apiEndpoint + "/login", {
      email,
      password,
    });
  }

  async function register(registerData) {
    return post(apiEndpoint + "/register", registerData);
  }

  async function sendResetPassword(email) {
    return post(apiEndpoint + "/reset-request", { email });
  }

  async function getResetInfo(userId, time, hash) {
    return get(
      apiEndpoint +
        "/reset-check?userId=" +
        userId +
        "&time=" +
        time +
        "&hash=" +
        hash
    );
  }

  async function resetPassword(passwordData) {
    return post(apiEndpoint + "/reset", passwordData);
  }

  async function logout() {
    localStorage.removeItem(userKey);
    post(apiEndpoint + "/logout");
  }

  return {
    login,
    sendResetPassword,
    getResetInfo,
    resetPassword,
    logout,
    register,
  };
};

export const tokenKey = "token";
export const userKey = "user";
export function getCurrentUser() {
  try {
    const user = localStorage.getItem(userKey);
    return JSON.parse(user);
  } catch (ex) {
    return null;
  }
}

export default authService;
