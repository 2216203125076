// @flow
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";

import PageTitle from "../../../components/PageTitle";

import Statistics from "./Statistics";
import Status from "./Status";
import LatestProjects from "./LatestProjects";
import useHttp from "../../../hooks/useHttp";
import ManDayStatistics from "./ManDayStatistics";
import ProjectSummary from "./ProjectSummary";

const ProjectDashboardPage = () => {
  const { projectDashboardService } = useHttp();
  const [data, setData] = useState(null);

  useEffect(() => {
    async function getData() {
      const { data } = await projectDashboardService.getData();
      setData(data.success);
    }

    // eslint-disable-next-line
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Dashboard", path: "/" },
          { label: "Projects", path: "/dashboard/project", active: true },
        ]}
        title={"Projects"}
      />

      <Statistics data={data} />

      <Row>
        <Col xxl={9}>
          <Row>
            <Col xxl={3} md={4}>
              <Status data={data} />
            </Col>
            <Col xxl={9} md={8}>
              <ManDayStatistics data={data} />
            </Col>
          </Row>
        </Col>

        <Col xxl={3}>
          <ProjectSummary data={data} />
        </Col>
      </Row>

      <Row>
        <Col xxl={9}>
          <LatestProjects data={data} />
        </Col>
      </Row>
    </>
  );
};

export default ProjectDashboardPage;
