// @flow
import React, { useState, useEffect } from "react";
import { Button, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useQuery } from "../../../../hooks/";

import { VerticalForm, FormInput } from "../../../../components/";
import AccountLayout from "../../../account/AccountLayout";
import { resetAuth } from "../../../../redux/actions";
import jwtDecode from "jwt-decode";
import useHttp from "../../../../hooks/useHttp";
import { userKey } from "../../../../services/authService";

const InviteConfirm = () => {
  const { t } = useTranslation();
  const { teamService } = useHttp();
  const dispatch = useDispatch();
  const [invitedUser, setInvitedUser] = useState({});
  const [serviceRes, setServiceRes] = useState("");

  const query = useQuery();
  const userId = query.get("userId");
  const hash = query.get("hash");

  useEffect(() => {
    dispatch(resetAuth());

    async function GetUser() {
      const checkResponse = await teamService.checkInvite(userId, hash);
      setInvitedUser(checkResponse?.data?.success);
      setServiceRes(checkResponse.data?.success?.message);
    }
    GetUser();
    // eslint-disable-next-line
  }, [dispatch, userId, hash]);

  const { loading, error } = useSelector((state) => ({
    loading: state.Auth.loading,
    user: state.Auth.user,
    error: state.Auth.error,
    userLoggedIn: state.Auth.userLoggedIn,
  }));

  /*
    form validation schema
    */
  const schemaResolver = yupResolver(
    yup.object().shape({
      password: yup.string().required(t("Please enter Password")),
    })
  );

  /*
    handle form submission
    */
  const onSubmit = async (formData) => {
    const acceptInviteRes = await teamService.acceptInvite({
      userId: userId,
      hash: hash,
      password: formData.password,
    });

    if (acceptInviteRes && acceptInviteRes.data.isError) {
      setServiceRes(acceptInviteRes.data.error.errorMessage);
    } else {
      const token = acceptInviteRes.data.success.token;
      const payload = jwtDecode(token);
      localStorage.setItem(userKey, JSON.stringify(payload));
      window.location = "/";
    }
  };

  return (
    <AccountLayout>
      <div className="text-center w-75 m-auto">
        <h4 className="text-dark-50 text-center mt-0 fw-bold">
          {t("Welcome To PmLab")}
        </h4>
        <p className="text-muted mb-4">
          {
            "You have heen invited to team X, please create your password to accept invitation"
          }
        </p>
      </div>

      {error && (
        <Alert variant="danger" className="my-2">
          {error}
        </Alert>
      )}

      {serviceRes && (
        <Alert variant="danger" className="my-2">
          {serviceRes}
        </Alert>
      )}

      <VerticalForm onSubmit={onSubmit} resolver={schemaResolver}>
        <FormInput
          label={t("Email")}
          type="text"
          name="email"
          containerClass={"mb-3"}
          value={invitedUser.email}
          disabled={true}
        />
        <FormInput
          label={t("Name")}
          type="text"
          name="name"
          containerClass={"mb-3"}
          value={invitedUser.name}
          disabled={true}
        />
        <FormInput
          label={t("Surname")}
          type="text"
          name="surname"
          containerClass={"mb-3"}
          value={invitedUser.surname}
          disabled={true}
        />
        <FormInput
          label={t("Password")}
          type="password"
          name="password"
          placeholder={t("Enter your password")}
          containerClass={"mb-3"}
        ></FormInput>

        <div className="mb-3 mb-0 text-center">
          <Button variant="primary" type="submit" disabled={loading}>
            {t("Create Account")}
          </Button>
        </div>
      </VerticalForm>
    </AccountLayout>
  );
};

export default InviteConfirm;
