import config from "../config/config";

const apiEndpoint = config.apiEndpoint + "/api/sprint-project";

const sprintProjectService = ({ get, post }) => {
  async function update(data) {
    return post(apiEndpoint, data);
  }

  async function remove(id) {
    return post(apiEndpoint + "/remove", { projectId: id });
  }

  async function setOrders(data) {
    return post(apiEndpoint + "/orders", data);
  }

  return {
    update,
    remove,
    setOrders,
  };
};

export default sprintProjectService;
