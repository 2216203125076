import React from "react";
import { Redirect } from "react-router-dom";
import { Route } from "react-router-dom";
import ResetPassword from "../pages/account/ResetPassword";
import ProjectCalendar from "../pages/apps/Projects/calender";
import ProjectTeam from "../pages/apps/Projects/Team";
import InviteConfirm from "../pages/apps/Projects/Team/invite";
import MyTasks from "../pages/apps/Tasks/MyTasks";
import ProjectDashboardPage from "../pages/dashboard/Project";

import PrivateRoute from "./PrivateRoute";

// lazy load all the views

// auth
const Login = React.lazy(() => import("../pages/account/Login"));
const Logout = React.lazy(() => import("../pages/account/Logout"));
const Register = React.lazy(() => import("../pages/account/Register"));
const ForgetPassword = React.lazy(() =>
  import("../pages/account/ForgetPassword")
);
const Projects = React.lazy(() => import("../pages/apps/Projects/"));
const ProjectDetail = React.lazy(() =>
  import("../pages/apps/Projects/Detail/")
);
const ProjectForm = React.lazy(() =>
  import("../pages/apps/Projects/ProjectForm")
);
const TaskList = React.lazy(() => import("../pages/apps/Tasks/List/"));
const TaskDetails = React.lazy(() => import("../pages/apps/Tasks/Details"));
const Kanban = React.lazy(() => import("../pages/apps/Tasks/Board/"));
const TeamDashboard = React.lazy(() => import("../pages/dashboard/team"));
const WorkLogDashboard = React.lazy(() =>
  import("../pages/dashboard/work-log")
);
const Workload = React.lazy(() => import("../pages/dashboard/workload"));
const Settings = React.lazy(() => import("../pages/apps/Projects/settings"));
const Sprint = React.lazy(() => import("../pages/apps/Projects/Sprint/"));
const Handbook = React.lazy(() => import("../pages/apps/Projects/Handbook/"));

// root routes
const rootRoute = {
  path: "/",
  exact: true,
  component: () => <Redirect to="/dashboard" />,
  route: PrivateRoute,
};

const projectAppRoutes = {
  path: "/apps/projects",
  name: "Projects",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "uil-briefcase",

  children: [
    {
      path: "/apps/projects/list",
      name: "List",
      component: Projects,
      route: PrivateRoute,
    },
    {
      path: "/apps/projects/details/:projectId",
      name: "Detail",
      component: ProjectDetail,
      route: PrivateRoute,
    },
    {
      path: "/apps/projects/new",
      name: "Create Project",
      component: ProjectForm,
      route: PrivateRoute,
    },
    {
      path: "/teams/:teamId",
      name: "Team Members",
      component: ProjectTeam,
      route: PrivateRoute,
    },
    {
      path: "/team",
      name: "Team Report",
      component: TeamDashboard,
      route: PrivateRoute,
    },
    {
      path: "/worklog",
      name: "Work Log Report",
      component: WorkLogDashboard,
      route: PrivateRoute,
    },
    {
      path: "/workload",
      name: "Workload",
      component: Workload,
      route: PrivateRoute,
    },
    {
      path: "/apps/projects/calender",
      name: "Project Calender",
      component: ProjectCalendar,
      route: PrivateRoute,
    },
    {
      path: "/members/:userId",
      name: "Team Member",
      component: MyTasks,
      route: PrivateRoute,
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: ProjectDashboardPage,
      route: PrivateRoute,
    },
    {
      path: "/sprint",
      name: "Sprint",
      component: Sprint,
      route: PrivateRoute,
    },
    {
      path: "/apps/projects/handbook",
      name: "Project Handbook",
      component: Handbook,
      route: PrivateRoute,
    },
  ],
};

const taskAppRoutes = {
  path: "/apps/tasks",
  name: "Tasks",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "uil-clipboard-alt",
  children: [
    {
      path: "/apps/tasks/list",
      name: "Task List",
      component: TaskList,
      route: PrivateRoute,
    },
    {
      path: "/apps/tasks/details",
      name: "Task List",
      component: TaskDetails,
      route: PrivateRoute,
    },
    {
      path: "/apps/tasks/kanban",
      name: "Kanban",
      component: Kanban,
      route: PrivateRoute,
    },
    {
      path: "/apps/tasks/self",
      name: "My Tasks",
      component: MyTasks,
      route: PrivateRoute,
    },
  ],
};

const userRoutes = {
  path: "/user/settings",
  name: "Settings",
  route: PrivateRoute,
  icon: "uil-cog",
  component: Settings,
};

const appRoutes = [projectAppRoutes, taskAppRoutes, userRoutes];

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// auth
const authRoutes = [
  {
    path: "/account/login",
    name: "Login",
    component: Login,
    route: Route,
  },
  {
    path: "/account/logout",
    name: "Logout",
    component: Logout,
    route: Route,
  },
  {
    path: "/account/register",
    name: "Register",
    component: Register,
    route: Route,
  },
  {
    path: "/account/forget-password",
    name: "Forget Password",
    component: ForgetPassword,
    route: Route,
  },
  {
    path: "/invite",
    name: "Invite Confirm",
    component: InviteConfirm,
    route: Route,
  },
  {
    path: "/password",
    name: "Check Reset Password",
    component: ResetPassword,
    route: Route,
  },
];

// All routes
const authProtectedRoutes = [rootRoute, ...appRoutes];
const publicRoutes = [...authRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);

export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};
