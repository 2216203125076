import config from "../config/config";

const apiEndpoint = config.apiEndpoint + "/api/worklog";

const workLogService = ({ get, post }) => {
  async function deleteLog(workLogId) {
    return post(apiEndpoint + "/delete", { workLogId });
  }

  async function create(workLog) {
    return post(apiEndpoint, workLog);
  }

  async function getTaskLogs(taskId) {
    return get(apiEndpoint + "/task-list?taskId=" + taskId);
  }

  async function getUserProjectGroup(startDate, endDate) {
    return get(
      apiEndpoint +
        "/group-user-project?startDate=" +
        startDate +
        "&endDate=" +
        endDate
    );
  }

  return { deleteLog, create, getTaskLogs, getUserProjectGroup };
};

export default workLogService;
