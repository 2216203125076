// @flow
import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

import CardTitle from "../../../components/CardTitle";

const Statistics = ({ data }) => {
  const history = useHistory();

  const navigateToProjects = (status) => {
    const params = new URLSearchParams({ status, viewType: "apps" });
    history.push(`/apps/projects/list?${params.toString()}`);
  };

  const statisticsData = [
    {
      icon: "mdi mdi-timer",
      variant: "success",
      title: "Completed Project",
      noOfProject: data?.completedProjectCount || 0,
      status: "completed",
    },
    {
      icon: "uil-briefcase",
      variant: "primary",
      title: "Active Project",
      noOfProject: data?.inProgressProjectCount || 0,
      status: "dev",
    },
    {
      icon: "mdi mdi-account-star",
      variant: "info",
      title: "Deployed Project",
      noOfProject: data?.deployedProjectCount || 0,
      status: "deployment",
    },
    {
      icon: "mdi mdi-folder-plus",
      variant: "warning",
      title: "New Project",
      noOfProject: data?.biddingProjectCount || 0,
      status: "budget",
    },
  ];

  return (
    <Row>
      {(statisticsData || []).map((statistics, index) => {
        return (
          <Col xl={3} sm={6} key={index.toString()}>
            <Card
              onClick={() => navigateToProjects(statistics.status)}
              style={{ cursor: "pointer" }}
            >
              <Card.Body>
                <CardTitle
                  containerClass="d-flex align-items-center justify-content-between"
                  title={
                    <>
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-sm">
                          <span
                            className={classNames(
                              "avatar-title",
                              "bg-" + statistics.variant + "-lighten",
                              "text-" + statistics.variant,
                              "rounded"
                            )}
                          >
                            <i
                              className={classNames(statistics.icon, "font-24")}
                            ></i>
                          </span>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="mt-0 mb-1">{statistics.title}</h5>
                        <p className="mb-0">{statistics.noOfProject}</p>
                      </div>
                    </>
                  }
                />
              </Card.Body>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
};

export default Statistics;
