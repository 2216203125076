export const ProjectStatus = {
  "not-started": {
    color: "secondary",
    name: "Not Started",
    icon: "mdi-human-queue",
  },
  dev: {
    color: "primary",
    name: "In Dev",
    icon: "mdi-pencil",
  },
  uat: {
    color: "info",
    name: "On UAT",
    icon: "mdi-ab-testing",
  },
  deployment: {
    color: "warning",
    name: "Waiting for Deployment",
    icon: "mdi-timer-sand",
  },
  "ready-for-invoice": {
    color: "dark",
    name: "Ready For Invoice",
    icon: "mdi-cash",
  },
  completed: {
    color: "success",
    name: "Completed",
    icon: "mdi-bookmark-check",
  },
  hold: {
    color: "warning",
    name: "On Hold",
    icon: "mdi-pause",
  },
  passive: {
    color: "danger",
    name: "Passive",
  },
  bid: {
    color: "warning",
    name: "Bid",
    icon: "mdi-forward",
  },
  budget: {
    color: "danger",
    name: "Budget",
    icon: "mdi-cash-multiple",
  },
  analysis: {
    color: "info",
    name: "Analysis",
    icon: "mdi-book-open-outline",
  },
};
