import { createContext, useContext, useState } from "react";
import { Alert } from "react-bootstrap";

export const AlertContext = createContext({});

export const AlertType = {
  Primary: "primary",
  Secondary: "secondary",
  Success: "success",
  Danger: "danger",
  Warning: "warning",
  Info: "info",
  Light: "light",
  Dark: "dark",
};

export function AlertProvider({ children }) {
  const [message, setMessage] = useState("How did this happend?");
  const [title, setTitle] = useState("Whoops");
  const [type, setAlertType] = useState(AlertType.Primary);
  const [visible, setVisible] = useState(false);

  const alert = ({ title, message, type }) => {
    setTitle(title);
    setMessage(message);
    setAlertType(type);
    showAlert();
  };

  const alertError = (message) => {
    setTitle("Error: ");
    setMessage(message);
    setAlertType(AlertType.Danger);
    showAlert();
  };

  const alertInfo = (message) => {
    setTitle("Info: ");
    setMessage(message);
    setAlertType(AlertType.Info);
    showAlert();
  };

  const alertSuccess = (message) => {
    setTitle("Success: ");
    setMessage(message);
    setAlertType(AlertType.Success);
    showAlert();
  };

  const showAlert = () => {
    setVisible(true);
    autoHide();
  };

  const autoHide = () => {
    setTimeout(() => {
      setVisible(false);
    }, 5000);
  };

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <AlertContext.Provider
      value={{ alert, alertError, alertInfo, alertSuccess }}
    >
      {visible && (
        <Alert
          style={{ position: "fixed", top: 75, right: 5, zIndex: 2000 }}
          variant={type}
          onClose={handleClose}
          dismissible
        >
          <strong>{title}</strong> {message}
        </Alert>
      )}
      {children}
    </AlertContext.Provider>
  );
}

export const useAlert = () => useContext(AlertContext);
