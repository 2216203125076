// @flow
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  InputGroup,
  Form,
  Dropdown,
  ButtonGroup,
  Modal,
} from "react-bootstrap";
import classNames from "classnames";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import WorkLogTable from "../../../../components/Task/workLogTable";
import HyperDatepicker from "../../../../components/Datepicker";
import { TaskProps } from "../../../../core/task-status";
import useHttp from "../../../../hooks/useHttp";
import { FormInput, VerticalForm } from "../../../../components";
import Attachments from "../Details/Attachments";
import TaskComment from "../../../../components/Comments/TaskComment";

const Task = ({
  task,
  handleTaskCompleteEvent,
  selectTask,
  saveTask,
  handleTaskDelete,
}) => {
  const { taskService, teamService } = useHttp();
  const [teamUsers, setTeamUsers] = useState([]);
  const [titleEditMode, setTitleEditMode] = useState(false);
  const [taskApproveModal, setTaskApproveModal] = useState(false);
  const [taskRejectModal, setTaskRejectModal] = useState(false);
  const [sendtoTestModal, setSendtoTestModal] = useState(false);

  const completed = task.status === "completed";

  useEffect(() => {
    async function GetTeams() {
      const teamUserResponse = await teamService.getTeamUsers();
      setTeamUsers(teamUserResponse.data.success);
    }
    GetTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const approveTestSchemaResolver = yupResolver(
    yup.object().shape({
      workLog: yup
        .number("Please enter a valid number")
        .positive("Please enter a positive number")
        .required("Please enter time you spent in hours"),
    })
  );

  const sendToTestSchemaResolver = yupResolver(
    yup.object().shape({
      workLog: yup
        .number("Please enter a valid number")
        .positive("Please enter a positive number")
        .required("Please enter time you spent in hours"),
      testAssignee: yup
        .number()
        .positive("Please select a user")
        .required("Please select a team member for testing"),
    })
  );

  const rejectTestSchemaResolver = yupResolver(
    yup.object().shape({
      workLog: yup
        .number("Please enter a valid number")
        .positive("Please enter a positive number")
        .required("Please enter time you spent in hours"),
      explanation: yup
        .string()
        .required("Please enter the reason for rejection"),
    })
  );

  const markCompleted = async (e) => {
    selectTask(task);
    await handleTaskCompleteEvent(task.id, e.target.checked);
  };

  const handleDelete = async () => {
    selectTask(task);
    await handleTaskDelete(task.id);
  };

  const handleAssignee = async (e) => {
    const selectedUserId = e.target.value;
    await taskService.updateAssignee(task.id, selectedUserId);

    const selectedTeamMember = teamUsers.find(
      (x) => Number(x.userId) === Number(selectedUserId)
    );

    task.assignTo = selectedUserId;
    task.user = {
      id: selectedUserId,
      name: selectedTeamMember.name,
      surname: selectedTeamMember.surname,
      code: selectedTeamMember.code,
      email: selectedTeamMember.email,
    };
    saveTask({ ...task });
  };

  const handleDueDate = async (e) => {
    await taskService.updateDueDate(task.id, e);
    task.dueDate = e.toISOString().slice(0, 10);
    saveTask({ ...task });
  };

  const handleDescription = async () => {
    await taskService.updateDescription(task.id, task.description);
    saveTask({ ...task });
  };

  const handleDescriptionChange = async (e) => {
    task.description = e.target.value;
    selectTask({ ...task });
  };

  const handleTitleChange = async (e) => {
    task.title = e.target.value;
    selectTask({ ...task });
  };

  const handleTitle = async () => {
    await taskService.updateTitle(task.id, task.title);
    setTitleEditMode(false);
    saveTask({ ...task });
  };

  const handleTitleEdit = () => {
    setTitleEditMode(!titleEditMode);
  };

  const handleStatusChange = async (status) => {
    await taskService.updateStatus(task.id, status);
    task.status = status;
    saveTask({ ...task });
  };

  const toggleTaskApproveModal = () => {
    setTaskApproveModal(!taskApproveModal);
  };

  const toggleTaskRejectModal = () => {
    setTaskRejectModal(!taskRejectModal);
  };

  const toggleSendToTestModal = () => {
    setSendtoTestModal(!sendtoTestModal);
  };

  const handleApproveTestSubmit = async (formData) => {
    const testTaskResponse = await taskService.approveTest(
      task.id,
      formData.workLog
    );
    const testTask = testTaskResponse.data.success;
    task.status = testTask.status;
    saveTask({ ...task });
  };

  const handleRejectTestSubmit = async (formData) => {
    const testTaskResponse = await taskService.rejectTest(
      task.id,
      formData.workLog,
      formData.explanation
    );
    const testTask = testTaskResponse.data.success;
    task.status = testTask.status;
    saveTask({ ...task });
  };

  const handleSendToTestSubmit = async (formData) => {
    const testTaskResponse = await taskService.sendtoTest(
      task.id,
      formData.workLog,
      formData.testAssignee
    );

    if (
      testTaskResponse &&
      testTaskResponse.data &&
      !testTaskResponse.data.isError
    ) {
      const testTask = testTaskResponse.data.success;
      task.status = testTask.status;
      saveTask({ ...task });
    }
  };

  const onCommentSave = (comments) => {
    task.TaskComments = comments;
    saveTask({ ...task });
  };

  return (
    <React.Fragment>
      <div className="mt-3"></div>
      <Card>
        <Card.Body>
          <div
            className="form-check float-end"
            style={{ height: 40, marginTop: -7 }}
          >
            <Button
              variant={"danger"}
              className="btn-icon"
              style={{ height: 35, float: "right" }}
              onClick={handleDelete}
            >
              <i className={classNames("mdi", "mdi-trash-can-outline")}></i>
            </Button>
          </div>

          <div className="form-check float-start">
            <input
              type="checkbox"
              className="form-check-input"
              id="completedCheck"
              checked={completed}
              onChange={markCompleted}
            />
            <label className="form-check-label" htmlFor="completedCheck">
              Mark as completed
            </label>
          </div>

          <hr className="mt-4 mb-2" />

          <Row>
            <Col>
              {!titleEditMode && (
                <h4>
                  {task.title}
                  {task.title && (
                    <Button
                      variant={"link"}
                      className="btn-icon"
                      onClick={handleTitleEdit}
                    >
                      <span className="mdi mdi-square-edit-outline"></span>
                    </Button>
                  )}
                </h4>
              )}
              {titleEditMode && (
                <InputGroup className="mb-3">
                  <Form.Control
                    value={task.title}
                    onChange={handleTitleChange}
                    onBlur={handleTitle}
                  />
                  {task.title && (
                    <Button
                      variant={"link"}
                      className="btn-icon"
                      onClick={handleTitleEdit}
                    >
                      <span className="mdi mdi-close"></span>
                    </Button>
                  )}
                </InputGroup>
              )}

              <Row>
                <Col>
                  <p className="mt-2 mb-1 text-muted">Assigned To</p>
                  <div className="d-flex">
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                      <select
                        name="assignedTo"
                        className="form-select"
                        onChange={handleAssignee}
                        value={task?.assignedTo}
                      >
                        <option value={-1}>Please Select</option>
                        {teamUsers &&
                          teamUsers.map((user) => {
                            return (
                              <option key={user.userId} value={user.userId}>
                                {user.name} {user.surname}
                              </option>
                            );
                          })}
                      </select>
                    </InputGroup>
                  </div>
                </Col>

                <Col>
                  <p className="mt-2 mb-1 text-muted">Due Date</p>
                  <div className="d-flex">
                    <HyperDatepicker
                      className="form-control"
                      value={
                        task?.dueDate ? new Date(task?.dueDate) : new Date()
                      }
                      onChange={handleDueDate}
                    />
                  </div>
                </Col>
              </Row>
              {task?.status !== "testing" && (
                <Row className="mt-1">
                  <Col>
                    <p className="mt-2 mb-1 text-muted">Status</p>

                    <Dropdown
                      as={ButtonGroup}
                      className="me-1"
                      onSelect={handleStatusChange}
                    >
                      <Dropdown.Toggle variant={TaskProps[task?.status]?.color}>
                        {TaskProps[task?.status]?.name}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {Object.keys(TaskProps).map((x, i) => {
                          return (
                            <Dropdown.Item key={i} eventKey={x}>
                              {TaskProps[x].name}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                  {task?.status !== "waiting-test" &&
                    task?.status !== "completed" && (
                      <Col>
                        <p className="mt-2 mb-1 text-muted">Test</p>
                        <Button
                          type="button"
                          variant="outline-success"
                          onClick={toggleSendToTestModal}
                        >
                          <i className="mdi mdi-file-send-outline"></i>
                          Send To Test
                        </Button>
                      </Col>
                    )}
                </Row>
              )}
              {task?.status === "testing" && (
                <Row className="mt-1">
                  <Col>
                    <Button
                      type="button"
                      variant="outline-success"
                      onClick={toggleTaskApproveModal}
                    >
                      <i className="mdi mdi-thumb-up-outline"></i> Approve
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type="button"
                      variant="outline-danger"
                      onClick={toggleTaskRejectModal}
                    >
                      <i className="mdi mdi-refresh"></i> Reject
                    </Button>
                  </Col>
                </Row>
              )}
              <Row className="mt-3">
                <Col>
                  <Attachments
                    projectId={task.projectId}
                    taskId={task.id}
                    attachments={task.attachments}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <p className="mt-2 mb-1 text-muted">Description</p>
                  <div className="d-flex">
                    <i className="font-18 text-success me-1"></i>
                    <div>
                      <textarea
                        name="description"
                        onBlur={handleDescription}
                        onChange={handleDescriptionChange}
                        rows="5"
                        cols={"35"}
                        className="form-control resize-none"
                        value={task?.description}
                      ></textarea>
                    </div>
                  </div>
                </Col>
              </Row>
              {task?.testExplanation && (
                <Row className="mt-3">
                  <Col>
                    <p className="mt-2 mb-1 text-muted">Test Explanation</p>
                    <div className="d-flex">
                      <i className="font-18 text-success me-1"></i>
                      <div>{task?.testExplanation}</div>
                    </div>
                  </Col>
                </Row>
              )}
              <Row className="mt-3">
                <Col>
                  <WorkLogTable workLogs={task.WorkLogs} taskId={task.id} />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <TaskComment
                    comments={task.TaskComments}
                    taskId={task.id}
                    onSave={onCommentSave}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Modal show={taskApproveModal} onHide={toggleTaskApproveModal}>
        <Modal.Header
          onHide={toggleTaskApproveModal}
          closeButton
          className={classNames("modal-colored-header", "bg-primary")}
        >
          <h4 className="modal-title">Approve Test</h4>
        </Modal.Header>
        <Modal.Body>
          <VerticalForm
            onSubmit={handleApproveTestSubmit}
            resolver={approveTestSchemaResolver}
            defaultValues={{ workLog: "" }}
          >
            <FormInput
              name="workLog"
              label="Please type hour you spent on this task"
              placeholder="Enter hour"
              type="number"
            />

            <div className="mb-3 mb-0 mt-2 text-center">
              <Button variant="primary" type="submit">
                Approve
              </Button>
            </div>
          </VerticalForm>
        </Modal.Body>
      </Modal>
      <Modal show={taskRejectModal} onHide={toggleTaskRejectModal}>
        <Modal.Header
          onHide={toggleTaskRejectModal}
          closeButton
          className={classNames("modal-colored-header", "bg-primary")}
        >
          <h4 className="modal-title">Reject Test</h4>
        </Modal.Header>
        <Modal.Body>
          <VerticalForm
            onSubmit={handleRejectTestSubmit}
            resolver={rejectTestSchemaResolver}
            defaultValues={{ workLog: "", explanation: "" }}
          >
            <FormInput
              name="workLog"
              label="Please type hour you spent on this task"
              placeholder="Enter hour"
              type="number"
            />

            <FormInput
              name="explanation"
              label="Please type reason for rejection"
              placeholder="Enter reason"
              type="text"
            />

            <div className="mb-3 mb-0 mt-2 text-center">
              <Button variant="primary" type="submit">
                Reject
              </Button>
            </div>
          </VerticalForm>
        </Modal.Body>
      </Modal>
      <Modal show={sendtoTestModal} onHide={toggleSendToTestModal}>
        <Modal.Header
          onHide={toggleSendToTestModal}
          closeButton
          className={classNames("modal-colored-header", "bg-primary")}
        >
          <h4 className="modal-title">Send To Test</h4>
        </Modal.Header>
        <Modal.Body>
          <VerticalForm
            onSubmit={handleSendToTestSubmit}
            resolver={sendToTestSchemaResolver}
            defaultValues={{ workLog: "", testAssignee: -1 }}
          >
            <FormInput
              name="workLog"
              label="Please type hour you spent on this task"
              placeholder="Enter hour"
              type="number"
            />

            <FormInput
              type="select"
              label="Please select a testing user"
              name="testAssignee"
            >
              <option value={-1}>Please Select</option>
              {teamUsers &&
                teamUsers.map((user) => {
                  return (
                    <option key={user.userId} value={user.userId}>
                      {user.name} {user.surname}
                    </option>
                  );
                })}
            </FormInput>

            <div className="mb-3 mb-0 mt-2 text-center">
              <Button variant="primary" type="submit">
                Send
              </Button>
            </div>
          </VerticalForm>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default Task;
