// @flow
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

const ConfirmModal = ({ header, bodyText, onApprove, isVisible, onCancel }) => {
  const [showConfirm, setShowConfirm] = useState(isVisible);

  useEffect(() => {
    setShowConfirm(isVisible);
  }, [isVisible]);

  const toggle = () => {
    setShowConfirm(!showConfirm);
  };

  const handleApprove = async () => {
    await onApprove();
    toggle();
  };

  const handleCancel = () => {
    onCancel();
    toggle();
  };

  return (
    <>
      <Modal show={showConfirm} onHide={toggle}>
        <Modal.Header onHide={toggle} closeButton>
          <h4 className="modal-title">{header}</h4>
        </Modal.Header>
        <Modal.Body>{bodyText}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleApprove}>
            Approve
          </Button>
          <Button variant="light" onClick={handleCancel}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmModal;
