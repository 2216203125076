import axios from "axios";
import { log } from "../services/logService";
import authService, { userKey } from "../services/authService";
import { AlertType, useAlert } from "./useAlert";
import notificationService from "../services/notificationService";
import projectSectionService from "../services/projectSectionService";
import projectService from "../services/projectService";
import taskService from "../services/taskService";
import teamService from "../services/teamService";
import workLogService from "../services/workLogService";
import attachmentService from "../services/attachmentService";
import userSettingService from "../services/userSettingService";
import teamSettingService from "../services/teamSettingService";
import taskCommentService from "../services/taskCommentService";
import projectCommentService from "../services/projectCommentService";
import favoriteProjectService from "../services/favoriteProjectService";
import projectImplementationService from "../services/projectImplementationService";
import projectDashboardService from "../services/projectDashboardService";
import projectLogService from "../services/projectLogService";
import sprintService from "../services/sprintService";
import sprintProjectService from "../services/sprintProjectService";

function ResponseInterceptor(response) {
  if (response.data.isError) {
    return Promise.reject({
      errorCode: response.data?.error?.errorKey?.toLowerCase(),
      errorStatus: response.data?.error?.errorCode,
    });
  }

  return response;
}

async function ResponseErrorInterceptor(error) {
  let err =
    error.code?.split("_").join("-").toLocaleLowerCase() ?? "unexpected-error";
  if (error.response) {
    const apiError = (error.response?.data).error;

    err = "404-not-found";

    if (apiError) {
      err = apiError.errorKey ?? "Unhandled Exception";
    }
  }

  log(error);

  return Promise.reject({
    errorCode: err.toLowerCase(),
    errorStatus: error.response?.status ?? 0,
  });
}

axios.interceptors.response.use(ResponseInterceptor, ResponseErrorInterceptor);

const useHttp = () => {
  const { alert, alertError } = useAlert();

  async function handleError({ errorCode, errorStatus }) {
    if (errorStatus === 401) {
      alert({
        title: "Session is Expired!",
        message: "Session is expired. Please login again!",
        type: AlertType.Info,
      });

      localStorage.removeItem(userKey);
      window.location.href = "/account/login";

      return;
    }
    const errorMsg =
      errorCode ??
      `Whoops, something went wrong.\n\nMaybe this helps:\n[${errorStatus}]: ${errorCode} `;

    alertError(errorMsg);
  }

  const post = async (url, data, config) => {
    return axios
      .post(url, data, { ...config, withCredentials: true })
      .catch(handleError);
  };

  const get = async (url, config) => {
    return axios
      .get(url, { ...config, withCredentials: true })
      .catch(handleError);
  };

  return {
    authService: authService({ get, post }),
    notificationService: notificationService({ get, post }),
    projectSectionService: projectSectionService({ post }),
    projectService: projectService({ get, post }),
    taskService: taskService({ get, post }),
    teamService: teamService({ get, post }),
    workLogService: workLogService({ get, post }),
    attachmentService: attachmentService({ get, post }),
    userSettingService: userSettingService({ get, post }),
    teamSettingService: teamSettingService({ get, post }),
    taskCommentService: taskCommentService({ get, post }),
    projectCommentService: projectCommentService({ get, post }),
    favoriteProjectService: favoriteProjectService({ get, post }),
    projectImplementationService: projectImplementationService({ get, post }),
    projectDashboardService: projectDashboardService({ get }),
    projectLogService: projectLogService({ get }),
    sprintService: sprintService({ get, post }),
    sprintProjectService: sprintProjectService({ get, post }),
  };
};

export default useHttp;
