// @flow
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React, { useState, useEffect } from "react";
import {
  Card,
  Dropdown,
  Table,
  Form,
  InputGroup,
  Button,
  Row,
  Col,
  Alert,
  Modal,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom";
import { FormInput, VerticalForm } from "../../../../components";
import ConfirmModal from "../../../../components/ConfirmModal";
import useHttp from "../../../../hooks/useHttp";

const ProjectTeam = () => {
  const { teamService } = useHttp();
  const { teamId } = useParams();

  const [teamUsers, setTeamUsers] = useState([]);
  const [teamName, setTeamName] = useState({});
  const [error, setError] = useState("");
  const [serviceRes, setServiceRes] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [userId, setUserId] = useState(0);
  const [showInviteModal, setShowInviteModal] = useState(false);

  useEffect(() => {
    GetTeam();
    // eslint-disable-next-line
  }, [teamId]);

  async function GetTeam() {
    const teamUserResponse = await teamService.getTeamUsersById(teamId);
    setTeamUsers(teamUserResponse.data.success);

    const teamResponse = await teamService.getTeam();

    setTeamName(teamResponse.data.success.name);
  }

  const inviteSchemaResolver = yupResolver(
    yup.object().shape({
      name: yup.string().required("Please enter name"),
      surname: yup.string().required("Please enter surname"),
      email: yup
        .string()
        .required("Please enter Email")
        .email("Please enter valid Email"),
      role: yup.string().required("Please select role"),
    })
  );

  const handleInviteSubmit = async (formData) => {
    const inviteResponse = await teamService.invite({
      teamId: teamId,
      name: formData["name"],
      surname: formData["surname"],
      email: formData["email"],
      role: formData["role"],
    });

    if (inviteResponse && inviteResponse.data.isError === false) {
      setShowInviteModal(false);
      setServiceRes("Invitation is sent successfully");
    } else if (inviteResponse && inviteResponse.data.isError === true) {
      setError(inviteResponse.data.error.errorMessage);
    } else {
      setError("unexpected error");
    }
  };

  const handleTeamNameChange = (e) => {
    setTeamName(e.target.value);
  };

  const handleSubmit = async () => {
    setError("");
    setServiceRes("");
    if (!teamName) setError("Please select a non-empty name");

    const updateRes = await teamService.updateTeam({
      teamId: teamId,
      name: teamName,
    });

    if (updateRes && updateRes.data.isError === false) {
      setServiceRes("Name is saved successfully");
    } else {
      setError(updateRes.data.error.errorMessage);
    }
  };

  const handleKick = async (userId) => {
    setUserId(userId);
    setShowConfirm(true);
  };

  const handleKickConfirm = async () => {
    await teamService.kick(userId, teamId);
    await GetTeam();
  };

  const handleKickCancel = () => {
    setShowConfirm(false);
    setUserId(0);
  };

  const toggleInviteModal = () => {
    setShowInviteModal(!showInviteModal);
  };

  return (
    <>
      {error && (
        <Alert variant="danger" className="my-2">
          {error}
        </Alert>
      )}

      {serviceRes && (
        <Alert variant="success" className="my-2">
          {serviceRes}
        </Alert>
      )}
      <Row className="mt-2">
        <Col sm={6}>
          <Form.Group>
            <Form.Label htmlFor="Button">Team Name</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Recipient's username"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                value={teamName}
                onChange={handleTeamNameChange}
              />
              <Button variant="dark" id="button-addon2" onClick={handleSubmit}>
                Save
              </Button>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>

      <Button
        variant="success"
        className="rounded-pill"
        onClick={toggleInviteModal}
      >
        <i className="mdi mdi-plus"></i> Invite Member
      </Button>

      <Card className="mt-2">
        <Card.Body>
          <Dropdown className="float-end" align="end">
            <Dropdown.Toggle
              variant="link"
              className="arrow-none card-drop p-0 shadow-none"
            >
              <i className="mdi mdi-dots-vertical"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <Link to="/team">Team Report</Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <h4 className="header-title mb-3">Team Members</h4>

          <Table hover responsive className="table-centered table-nowrap mb-0">
            <tbody>
              {teamUsers &&
                teamUsers.map((user) => {
                  return (
                    <tr key={user.userId}>
                      <td>
                        <div className="d-flex align-items-start">
                          <div
                            className="rounded-circle bg-primary-lighten text-primary"
                            style={{
                              height: 35,
                              width: 35,
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              marginRight: 30,
                            }}
                          >
                            <span>{user?.code}</span>
                          </div>
                          <div>
                            <h5 className="mt-0 mb-1">
                              {user.name} {user.surname}
                              <small className="fw-normal ms-3">
                                {user.createdAt}
                              </small>
                            </h5>
                            <span className="font-13">{user.email}</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className="text-muted font-13">Status</span>{" "}
                        <br />
                        <p className="mb-0">{user.status}</p>
                      </td>
                      <td>
                        <span className="text-muted font-13">Role</span> <br />
                        <p className="mb-0">{user.role}</p>
                      </td>
                      <td className="table-action" style={{ width: "50px" }}>
                        <Dropdown className="float-end" align="end">
                          <Dropdown.Toggle
                            variant="link"
                            className="arrow-none card-drop p-0 shadow-none"
                          >
                            <i className="mdi mdi-dots-horizontal"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              href={`/members/${user.userId}?userName=${user.name} ${user.surname}`}
                            >
                              View Tasks
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item>
                              <Button
                                type="button"
                                size="sm"
                                variant="outline-danger"
                                onClick={() => {
                                  handleKick(user.userId);
                                }}
                              >
                                <i className="mdi mdi-trash-can me-1"></i>Remove
                              </Button>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Modal show={showInviteModal} onHide={toggleInviteModal} centered>
        <Modal.Body>
          <VerticalForm
            onSubmit={handleInviteSubmit}
            resolver={inviteSchemaResolver}
            defaultValues={{}}
          >
            <FormInput
              name="name"
              label="Name"
              placeholder="Enter user name"
              containerClass="mb-3"
            />
            <FormInput
              name="surname"
              label="Surname"
              placeholder="Enter user surname"
              containerClass="mb-3"
            />
            <FormInput
              label={"Email address"}
              type="email"
              name="email"
              placeholder={"Enter your email"}
              containerClass="mb-3"
            />

            <FormInput
              name="role"
              type="select"
              className="form-select"
              containerClass="mb-3"
              label="Role"
            >
              <option value={""}>Please Select</option>
              <option value={"developer"}>Developer</option>
              <option value={"manager"}>Manager</option>
              <option value={"analist"}>Analyst</option>
            </FormInput>

            <div className="mb-3 text-center">
              <button className="btn btn-primary" type="submit">
                Invite
              </button>
            </div>
          </VerticalForm>
        </Modal.Body>
      </Modal>
      <ConfirmModal
        isVisible={showConfirm}
        header="Confirm remove?"
        bodyText={"You are about to remove member from you team. Are you sure"}
        onApprove={handleKickConfirm}
        onCancel={handleKickCancel}
      />
    </>
  );
};

export default ProjectTeam;
