import config from "../config/config";
import { getCurrentUser } from "./authService";

const apiEndpoint = config.apiEndpoint + "/api/team";

const teamService = ({ get, post }) => {
  async function getTeam() {
    return get(apiEndpoint + "?teamId=" + getCurrentUser().teams[0].teamId);
  }

  async function updateTeam(team) {
    return post(apiEndpoint + "/update", team);
  }

  async function getTeamUsers() {
    return get(
      apiEndpoint + "/members?teamId=" + getCurrentUser().teams[0].teamId
    );
  }

  async function getTeamUsersById(teamId) {
    return get(apiEndpoint + "/members?teamId=" + teamId);
  }

  async function invite(inviteRequest) {
    return post(apiEndpoint + "/invite", inviteRequest);
  }

  async function checkInvite(userId, hash) {
    return get(
      apiEndpoint + "/invite/check?userId=" + userId + "&hash=" + hash
    );
  }

  async function acceptInvite(acceptRequest) {
    return post(apiEndpoint + "/invite/accept", acceptRequest);
  }

  async function kick(userId, teamId) {
    return post(apiEndpoint + "/kick", { userId, teamId });
  }

  return {
    getTeam,
    updateTeam,
    getTeamUsers,
    getTeamUsersById,
    checkInvite,
    acceptInvite,
    kick,
    invite,
  };
};

export default teamService;
