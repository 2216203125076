import config from "../config/config";
import { getCurrentUser } from "./authService";

const apiEndpoint = config.apiEndpoint + "/api/favorite-project";

const favoriteProjectService = ({ get, post }) => {
  async function getProjectList() {
    const projectResponse = get(
      apiEndpoint + "/list?teamId=" + getCurrentUser().teams[0].teamId
    );

    return projectResponse;
  }

  async function setFavorite(projectId) {
    return post(apiEndpoint + "/add", { projectId });
  }

  async function deleteFavorite(projectId) {
    return post(apiEndpoint + "/remove", { projectId });
  }

  return {
    getProjectList,
    setFavorite,
    deleteFavorite,
  };
};

export default favoriteProjectService;
