// @flow
import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Offcanvas, Button } from "react-bootstrap";
import classNames from "classnames";
import { useParams } from "react-router-dom/cjs/react-router-dom";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { FormInput, VerticalForm } from "../../../../components/";
import TaskSection from "../../Tasks/List/Section";
import Task from "../../Tasks/List/Task";

import { getStartOfNextWeek, getToday } from "../../../../utils/date";
import useHttp from "../../../../hooks/useHttp";
import { useQuery } from "../../../../hooks";
import StatusFilterDropdown, {
  statusFiltersKey,
} from "../../../../components/StatusFilterDropdown";

const myTaskSection = {
  Overdue: 1,
  Today: 2,
  ThisWeek: 3,
  Upcoming: 4,
  OnHold: 5,
};

const myTaskSections = [
  { id: myTaskSection.Overdue, name: "Overdue" },
  { id: myTaskSection.Today, name: "Today" },
  { id: myTaskSection.ThisWeek, name: "This Week" },
  { id: myTaskSection.Upcoming, name: "Upcoming" },
  { id: myTaskSection.OnHold, name: "On Hold" },
];

const MyTasks = () => {
  const { taskService } = useHttp();
  const { userId } = useParams();
  const query = useQuery();
  const userName = query.get("userName");

  const [selectedTask, setSelectedTask] = useState({});
  const [taskCompleteModal, setTaskCompleteModal] = useState(false);
  const [allTasks, setAllTasks] = useState([]);
  const [showTaskCanvas, setShowTaskCanvas] = useState(false);
  const [filteredTasks, setFilteredTasks] = useState([]);

  const toggleTaskCanvas = () => {
    setShowTaskCanvas(!showTaskCanvas);
  };

  const loadTasks = async () => {
    const taskResponse =
      userId > 0
        ? await taskService.listUserTasks(userId)
        : await taskService.listSelfTasks();

    const allTasksRes = taskResponse.data.success;
    await filterTasks(allTasksRes);
    partitionTasks(allTasksRes);
  };

  const partitionTasks = (allTasks) => {
    setMyTaskSections(allTasks);
  };

  useEffect(() => {
    async function Load() {
      await loadTasks();
    }
    Load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function applyFilter() {
      if (allTasks) await filterTasks();
    }
    applyFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTasks]);

  async function filterTasks() {
    const tasks = allTasks;
    if (tasks && tasks.length > 0) {
      let filtered = [];
      const statusFilters = getStatusFilters();
      if (statusFilters.length > 0) {
        filtered = tasks.filter((x) => statusFilters.includes(x.status));
      } else {
        filtered = tasks;
      }

      setFilteredTasks(filtered);
    } else {
      setFilteredTasks(tasks);
    }
  }

  const getStatusFilters = () => {
    const localStatuses = localStorage.getItem(statusFiltersKey);
    return localStatuses ? JSON.parse(localStatuses) : [];
  };

  const saveTask = (task) => {
    setSelectedTask(task);
    updateTaskList(task);
    setShowTaskCanvas(false);
  };

  const updateTaskList = (task) => {
    const taskIndex = filteredTasks.findIndex((x) => x.id === task.id);

    if (taskIndex >= 0) {
      filteredTasks[taskIndex] = task;
      partitionTasks(filteredTasks);
    }
  };

  function orderByDateAsc(taskA, taskB) {
    if (taskA.dueDate < taskB.dueDate) return -1;
    if (taskA.dueDate > taskB.dueDate) return 1;
    return 0;
  }

  const setMyTaskSections = (tasks) => {
    const tasksWithSections = tasks.map((task) => {
      const today = getToday();
      const nextWeek = getStartOfNextWeek();
      const newSection =
        task.status === "on-hold"
          ? myTaskSection.OnHold
          : task.dueDate === today
          ? myTaskSection.Today
          : task.dueDate < today
          ? myTaskSection.Overdue
          : task.dueDate >= nextWeek
          ? myTaskSection.Upcoming
          : task.dueDate < nextWeek && task.dueDate > today
          ? myTaskSection.ThisWeek
          : 0;
      return { ...task, sectionId: newSection };
    });

    setAllTasks(tasksWithSections.sort(orderByDateAsc));
  };

  const selectTask = (task) => {
    setShowTaskCanvas(true);
    setSelectedTask(task);
  };

  const toggleTaskCompleteModal = () => {
    setTaskCompleteModal(!taskCompleteModal);
  };

  const schemaResolver = yupResolver(
    yup.object().shape({
      title: yup.string().required("Please enter task title"),
      description: yup.string().required("Please enter task overview"),
      assignedTo: yup
        .number()
        .positive()
        .required("Please assign task to someone"),
    })
  );

  const workLogSchemaResolver = yupResolver(
    yup.object().shape({
      workLog: yup
        .number()
        .positive()
        .required("Please enter time you spent in hours"),
    })
  );

  /*
   * form methods
   */
  const methods = useForm({ resolver: schemaResolver });
  const { reset } = methods;

  const handleValidCompleteSubmit = async (formData) => {
    const newTask = {
      taskId: selectedTask.id,
      workLog: formData.workLog,
    };

    await taskService.completeTask(newTask);
    await loadTasks();
    setTaskCompleteModal(false);
    resetForm();
  };

  const resetForm = () => {
    reset();
  };

  const handleTaskCompleteEvent = async (taskId, isCompleted) => {
    if (isCompleted) {
      setTaskCompleteModal(isCompleted);
    } else {
      await taskService.completeUndoTask({ taskId });
      await loadTasks();
    }
  };

  const handleTaskDelete = async (taskId) => {
    await taskService.deleteTask({ taskId });
    await loadTasks();
    setSelectedTask({});
  };

  return (
    <>
      <Row>
        <Col xl={8}>
          <div className="page-title-box">
            <div className="d-flex justify-content-end align-items-center page-title-right">
              <StatusFilterDropdown
                filterTasks={filterTasks}
              ></StatusFilterDropdown>
            </div>
            <h4 className="page-title">
              {userId > 0 && userName ? `${userName}'s Tasks` : "My Tasks"}
            </h4>
          </div>
          {/* filters */}

          {/* tasks */}
          <div className="mt-2">
            <TaskSection
              tasks={filteredTasks}
              selectTask={selectTask}
              handleTaskCompleteEvent={handleTaskCompleteEvent}
              isDraggable={false}
              sections={myTaskSections}
            ></TaskSection>
          </div>
          <Modal show={taskCompleteModal} onHide={toggleTaskCompleteModal}>
            <Modal.Header
              onHide={toggleTaskCompleteModal}
              closeButton
              className={classNames("modal-colored-header", "bg-primary")}
            >
              <h4 className="modal-title">Work Log Required!</h4>
            </Modal.Header>
            <Modal.Body>
              <VerticalForm
                onSubmit={handleValidCompleteSubmit}
                resolver={workLogSchemaResolver}
                defaultValues={{ workLog: "" }}
              >
                <FormInput
                  name="workLog"
                  label="Please type hour you spent on this task"
                  placeholder="Enter hour"
                  type="number"
                />

                <div className="mb-3 mb-0 mt-2 text-center">
                  <Button variant="primary" type="submit">
                    Create
                  </Button>
                </div>
              </VerticalForm>
            </Modal.Body>
          </Modal>
        </Col>
        <Col>
          <Offcanvas
            show={showTaskCanvas}
            onHide={toggleTaskCanvas}
            placement={"end"}
            scroll={true}
            backdrop={false}
          >
            <Offcanvas.Body>
              <Task
                task={selectedTask}
                selectTask={selectTask}
                handleTaskDelete={handleTaskDelete}
                handleTaskCompleteEvent={handleTaskCompleteEvent}
                saveTask={saveTask}
              />
            </Offcanvas.Body>
          </Offcanvas>
        </Col>
      </Row>
    </>
  );
};

export default MyTasks;
