import config from "../config/config";

const apiEndpoint = config.apiEndpoint + "/api/user-setting";

const userSettingService = ({ get, post }) => {
  async function save(settingKey, settingValue) {
    return post(apiEndpoint, { settingKey, settingValue });
  }

  async function getAll() {
    return get(apiEndpoint + "/all");
  }

  return { save, getAll };
};

export default userSettingService;
