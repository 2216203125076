import config from "../config/config";

const apiEndpoint = config.apiEndpoint + "/api/project-implementation";

const projectImplementationService = ({ get, post }) => {
  async function getTypes() {
    return get(apiEndpoint + "/types");
  }

  async function getProjectImplementations(projectId) {
    return get(apiEndpoint + "/list?projectId=" + projectId);
  }

  async function add(data) {
    return post(apiEndpoint, data);
  }

  async function update(data) {
    return post(apiEndpoint + "/update", data);
  }

  async function remove(id) {
    return post(apiEndpoint + "/remove", { implementationId: id });
  }

  async function exportToPdf(data) {
    return post(apiEndpoint + "/export", data, { responseType: "blob" });
  }

  return {
    getTypes,
    getProjectImplementations,
    add,
    update,
    remove,
    exportToPdf,
  };
};

export default projectImplementationService;
