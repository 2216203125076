import moment from "moment";

const getTimeText = (date) => {
  var seconds = Math.floor((new Date() - new Date(date)) / 1000);
  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " min ago";
  }
  return Math.floor(seconds) + " sec ago";
};

const getFutureTimeText = (date) => {
  const currentDate = new Date();
  const dueDate = new Date(date);

  const timeDifference = dueDate.getTime() - currentDate.getTime();
  const millisecondsInDay = 1000 * 60 * 60 * 24;
  const millisecondsInMonth = millisecondsInDay * 30.44;
  const millisecondsInYear = millisecondsInDay * 365.25;

  if (timeDifference <= 0) {
    return getTimeText(date);
  }

  const years = Math.floor(timeDifference / millisecondsInYear);
  const months = Math.floor(
    (timeDifference % millisecondsInYear) / millisecondsInMonth
  );
  const days = Math.floor(
    (timeDifference % millisecondsInMonth) / millisecondsInDay
  );

  if (years > 0) {
    return `Due in ${years} year${years > 1 ? "s" : ""}`;
  } else if (months > 0) {
    return `Due in ${months} month${months > 1 ? "s" : ""}`;
  } else if (days > 0) {
    return `Due in ${days} day${days > 1 ? "s" : ""}`;
  } else {
    return "Due today";
  }
};

const subtractDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
};

const toShortDate = (inputDate) => {
  if (!inputDate) {
    return "";
  }

  var date = new Date(inputDate);
  var options = { month: "short", day: "numeric", year: "numeric" };
  var formatter = new Intl.DateTimeFormat(undefined, options);
  return formatter.format(date);
};

const toShortDateWithoutYear = (inputDate) => {
  if (!inputDate) {
    return "";
  }

  var date = new Date(inputDate);
  var options = { month: "short", day: "numeric" };
  var formatter = new Intl.DateTimeFormat(undefined, options);
  return formatter.format(date);
};

const generateDateArray = (start, end) => {
  const dates = [];
  let currentDate = moment(start);
  while (currentDate.isSameOrBefore(end)) {
    dates.push(currentDate.format("YYYY-MM-DD"));
    currentDate = currentDate.add(1, "days");
  }
  return dates;
};

export {
  getTimeText,
  getFutureTimeText,
  subtractDays,
  toShortDate,
  toShortDateWithoutYear,
  generateDateArray,
};
