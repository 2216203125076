import config from "../config/config";

const apiEndpoint = config.apiEndpoint + "/api/project-log";

const projectLogService = ({ get }) => {
  async function getLogs(projectId) {
    return get(apiEndpoint + "?projectId=" + projectId);
  }

  return { getLogs };
};

export default projectLogService;
